<div class="h-100 d-flex flex-column align-items-center justify-content-center">
    <h2 class="mb-4">Client Portal</h2>
    <wdx-icon
        class="mb-5"
        icon="right-from-bracket"
        size="4x"
        animation="beat"
    ></wdx-icon>
    <p class="fs-4 justify-self-center text-center mb-3">
        You will be logged out in
        <strong>{{ seconds }}</strong> seconds due to inactivity.
    </p>
    <p class="fs-5">To stay logged in please interact with the app.</p>
</div>
