import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './party-url-segment';

@Injectable({ providedIn: 'root' })
export class PartyApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getAvatar(partyId: string): Observable<HttpResponse<Blob>> {
        return this.get(`${UrlSegment.Party}/${partyId}/${UrlSegment.Avatar}`, {
            observe: 'response',
            responseType: 'blob' as 'json',
        });
    }
}
