import { Action, createReducer, on } from '@ngrx/store';
import * as portfolioDataActions from './portfolio-data.actions';
import { CrudStatus, setCrudStatus } from '@wdx/shared/utils';
import {
    LineChart,
    Performance,
    PerformancePeriod,
    SecurityPosition,
    SecurityTransaction,
    StandingOrder,
    ValuationSummary,
} from '@wdx/portal/api-models';
import { PortfolioPerformanceChartTypes } from '@wdx/shared/components/portfolio';

export const PORTFOLIO_DATA_FEATURE_KEY = 'portfolio-data';

export interface PortfolioDataStateProperty<T> {
    entityId: string;
    data: T | null;
    status: CrudStatus;
    statusString: string;
    error?: any;
}

export interface PerformanceDataStateProperty {
    entityId: string;
    performancePeriod: PerformancePeriod;
    chartType: PortfolioPerformanceChartTypes;
    data: LineChart | null;
    status: CrudStatus;
    statusString: string;
    error?: any;
}

export interface PortfolioDataState {
    assetsData?: PortfolioDataStateProperty<ValuationSummary>;
    performance?: PerformanceDataStateProperty;
    performanceSummary?: PortfolioDataStateProperty<Performance>;
    positions?: PortfolioDataStateProperty<SecurityPosition[]>;
    standingOrders?: PortfolioDataStateProperty<StandingOrder[]>;
    transactions?: PortfolioDataStateProperty<SecurityTransaction[]>;
}

export const initialState: PortfolioDataState = {
    assetsData: undefined,
    performance: undefined,
    performanceSummary: undefined,
    positions: undefined,
    transactions: undefined,
};

export type PORTFOLIO_DATA_STATE = {
    [PORTFOLIO_DATA_FEATURE_KEY]: PortfolioDataState;
};

const reducerSetup = createReducer(
    initialState,

    on(
        portfolioDataActions.getPortfolioTransactions,
        (state, props): PortfolioDataState => ({
            ...state,
            transactions: {
                entityId: props.entityId,
                data: null,
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        portfolioDataActions.getPortfolioTransactionsSuccess,
        (state, props): PortfolioDataState => ({
            ...state,
            transactions: {
                entityId: props.entityId,
                data: props.data,
                ...setCrudStatus(CrudStatus.Success),
            },
        })
    ),

    on(
        portfolioDataActions.getPortfolioTransactionsError,
        (state, props): PortfolioDataState => ({
            ...state,
            transactions: {
                entityId: props.entityId,
                error: props.error,
                ...setCrudStatus(CrudStatus.Error),
                data: null,
            },
        })
    ),

    on(
        portfolioDataActions.getAssetsData,
        (state, props): PortfolioDataState => ({
            ...state,
            assetsData: {
                entityId: props.entityId,
                data: null,
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        portfolioDataActions.getAssetsDataSuccess,
        (state, props): PortfolioDataState => ({
            ...state,
            assetsData: {
                entityId: props.entityId,
                data: props.data,
                ...setCrudStatus(CrudStatus.Success),
            },
        })
    ),

    on(
        portfolioDataActions.getAssetsDataError,
        (state, props): PortfolioDataState => ({
            ...state,
            assetsData: {
                entityId: props.entityId,
                error: props.error,
                data: null,
                ...setCrudStatus(CrudStatus.Error),
            },
        })
    ),

    on(
        portfolioDataActions.getPositions,
        (state, props): PortfolioDataState => ({
            ...state,
            positions: {
                entityId: props.entityId,
                data: null,
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        portfolioDataActions.getPositionsSuccess,
        (state, props): PortfolioDataState => ({
            ...state,
            positions: {
                entityId: props.entityId,
                data: props.data,
                ...setCrudStatus(CrudStatus.Success),
            },
        })
    ),

    on(
        portfolioDataActions.getPositionsError,
        (state, props): PortfolioDataState => ({
            ...state,
            positions: {
                entityId: props.entityId,
                error: props.error,
                data: null,
                ...setCrudStatus(CrudStatus.Error),
            },
        })
    ),

    on(
        portfolioDataActions.getStandingOrders,
        (state, props): PortfolioDataState => ({
            ...state,
            standingOrders: {
                entityId: props.entityId,
                data: null,
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        portfolioDataActions.getStandingOrdersSuccess,
        (state, props): PortfolioDataState => ({
            ...state,
            standingOrders: {
                entityId: props.entityId,
                data: props.data,
                ...setCrudStatus(CrudStatus.Success),
            },
        })
    ),

    on(
        portfolioDataActions.getStandingOrdersError,
        (state, props): PortfolioDataState => ({
            ...state,
            standingOrders: {
                entityId: props.entityId,
                error: props.error,
                ...setCrudStatus(CrudStatus.Error),
                data: null,
            },
        })
    ),

    on(
        portfolioDataActions.getPerformance,
        (state, props): PortfolioDataState => ({
            ...state,
            performance: {
                entityId: props.entityId,
                performancePeriod: props.performancePeriod,
                chartType: props.chartType,
                data: null,
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        portfolioDataActions.getPerformanceSuccess,
        (state, props): PortfolioDataState => ({
            ...state,
            performance: {
                entityId: props.entityId,
                performancePeriod: props.performancePeriod,
                chartType: props.chartType,
                data: props.data,
                ...setCrudStatus(CrudStatus.Success),
            },
        })
    ),

    on(
        portfolioDataActions.getPerformanceError,
        (state, props): PortfolioDataState => ({
            ...state,
            performance: {
                entityId: props.entityId,
                performancePeriod: props.performancePeriod,
                chartType: props.chartType,
                error: props.error,
                data: null,
                ...setCrudStatus(CrudStatus.Error),
            },
        })
    ),

    on(
        portfolioDataActions.getPerformanceSummary,
        (state, props): PortfolioDataState => ({
            ...state,
            performanceSummary: {
                entityId: props.entityId,
                data: null,
                ...setCrudStatus(CrudStatus.Loading),
            },
        })
    ),

    on(
        portfolioDataActions.getPerformanceSummarySuccess,
        (state, props): PortfolioDataState => ({
            ...state,
            performanceSummary: {
                entityId: props.entityId,
                data: props.data,
                ...setCrudStatus(CrudStatus.Success),
            },
        })
    ),

    on(
        portfolioDataActions.getPerformanceSummaryError,
        (state, props): PortfolioDataState => ({
            ...state,
            performanceSummary: {
                entityId: props.entityId,
                error: props.error,
                data: null,
                ...setCrudStatus(CrudStatus.Error),
            },
        })
    )
);

export function portfolioDataReducer(
    state: PortfolioDataState | undefined,
    action: Action
) {
    return reducerSetup(state, action);
}
