import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
    getBooleanKey(key: string): boolean {
        return Boolean(localStorage.getItem(key));
    }

    setBooleanKey(key: string, value: boolean) {
        if (value) {
            window.localStorage.setItem(key, 'true');
        } else {
            localStorage.removeItem(key);
        }
    }

    getStringKey(key: string): string {
        return localStorage.getItem(key) as string;
    }

    setStringKey(key: string, value?: string) {
        if (value) {
            window.localStorage.setItem(key, value);
        } else {
            localStorage.removeItem(key);
        }
    }
}
