<div
    *ngIf="base64Logo$ | async as base64Logo"
    class="d-flex align-items-center"
>
    <a
        class="d-none d-md-block navbar-brand p-0 me-2 divider-end"
        routerLink="/"
    >
        <img height="36" [src]="base64Logo" />
    </a>

    <span class="fs-5 ms-2"> {{ tenantName$ | async }}</span>
</div>
