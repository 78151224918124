import {
    ActivatedRouteSnapshot,
    ResolveFn,
    RouterStateSnapshot,
} from '@angular/router';
import { BreadcrumbService } from '../services/breadcrumbs/breadcrumb.service';
import { inject } from '@angular/core';
import { Observable } from 'rxjs';

export const breadcrumbResolver =
    (labelProvider?: Observable<string>): ResolveFn<void | Observable<void>> =>
    (
        snapshot: ActivatedRouteSnapshot,
        __: RouterStateSnapshot,
        breadcrumbService = inject(BreadcrumbService)
    ): void | Observable<void> => {
        const routeTree = [snapshot];

        if (labelProvider) {
            // TODO implement observable path
        }

        while (routeTree[routeTree.length - 1]?.['parent']) {
            routeTree.push(routeTree[routeTree.length - 1]?.['parent'] as any);
        }
        const breadcrumbs = routeTree.filter(
            (breadcrumb) =>
                ![null, undefined].includes(breadcrumb.data?.['breadcrumb'])
        );

        const mappedBreadcrumbs = breadcrumbs
            .map((breadcrumb, i) => {
                const paths = breadcrumbs
                    .slice(i, breadcrumbs.length - 1)
                    .map((bcrumb) => bcrumb.url[0]?.path || '')
                    .reverse();
                const path =
                    paths && paths.length ? '/' + paths.join('/') : '/';

                return {
                    label: breadcrumb.data?.['breadcrumb'],
                    path,
                };
            })
            .reverse();
        breadcrumbService.updateBreadcrumbs(mappedBreadcrumbs);
    };
