import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { TeamListMember } from '@wdx/portal/api-models';

export const TeamActions = createActionGroup({
    source: 'Team',
    events: {
        'Get Listing': emptyProps(),
        'Get Listing From API': props<{ response: TeamListMember[] }>(),
        'Get Listing From Store': emptyProps(),
        'Get Listing Error': emptyProps(),
    },
});
