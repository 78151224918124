<nav *ngIf="breadcrumbs$ | ngrxPush as breadcrumbs">
    <ol class="breadcrumb">
        <ng-container *ngIf="breadcrumbs.length > 0; else noBreadcrumbs">
            <li *ngFor="let breadcrumb of breadcrumbs; let last = last">
                <span *ngIf="last">
                    {{ breadcrumb?.label }}
                </span>
                <a
                    *ngIf="!last"
                    class="fw-normal"
                    [routerLink]="breadcrumb.path"
                    (click)="onNavigation(breadcrumb)"
                >
                    {{ breadcrumb?.label }}
                </a>
            </li></ng-container
        >
        <ng-template #noBreadcrumbs>
            <li>&nbsp;</li>
        </ng-template>
    </ol>
</nav>
