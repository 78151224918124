import { createAction, props } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/portal/api-models';
import { ContextualDataContext } from '@wdx/shared/infrastructure/form-framework';

export const getSelectOptions = createAction(
    '[Selects] Get Select Options',
    props<{
        id: string;
    }>()
);

export const getSelectOptionsSuccess = createAction(
    '[Selects] Get Select Options Success',
    props<{
        id: string;
        results: SelectFieldResult[];
    }>()
);

export const getSelectOptionsFailure = createAction(
    '[Selects] Get Select Options Failure',
    props<{
        id: string;
        error: Error;
    }>()
);

export const getContextualSelect = createAction(
    '[Selects] Get Contextual Select Options',
    props<{
        id: string;
        source: string;
        lookups: any[];
        context?: ContextualDataContext[];
    }>()
);

export const getContextualSelectOptionsSuccess = createAction(
    '[Selects] Get Contextual Select Options Success',
    props<{
        id: string;
        results: SelectFieldResult[];
    }>()
);

export const getContextualSelectOptionsFailure = createAction(
    '[Selects] Get Contextual Select Options Failure',
    props<{
        id: string;
        error: Error;
    }>()
);
