import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as externalTasksActions from './external-tasks.actions';
import { ExternalTasksService } from './external-tasks.service';

@Injectable()
export class ExternalTasksEffects {
    private actions$ = inject(Actions);
    private externalTasksService = inject(ExternalTasksService);

    loadExternalTasks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(externalTasksActions.loadExternalTasks),
            switchMap(() =>
                this.externalTasksService.getTasks().pipe(
                    map((tasks) =>
                        externalTasksActions.loadExternalTasksSuccess({ tasks })
                    ),
                    catchError((error) => {
                        return of(
                            externalTasksActions.loadExternalTasksFailure({
                                error,
                            })
                        );
                    })
                )
            )
        )
    );
}
