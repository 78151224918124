import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breadcrumb } from '@wdx/shared/utils';

@Injectable({
    providedIn: 'root',
})
export class BreadcrumbService {
    concatRoutePath = '';
    paramID = '';
    breadcrumbsData: any = [];
    dynamicDataAdded = false;
    routerEventEnd$ = new BehaviorSubject<any>(null);

    private breadcrumbSubject = new BehaviorSubject<Breadcrumb[]>([]);
    public breadcrumbs$: Observable<Breadcrumb[]> =
        this.breadcrumbSubject.asObservable();

    updateBreadcrumbs(data: Breadcrumb[]) {
        this.breadcrumbSubject.next(data);
    }

    /**
     * Replaces last entry in breadcrumb using supplied label only
     * Will do nothing if there is only one (the root) breadbcrumb
     * @param label label to use in breadcrumb
     */
    setDynamicName(label: string) {
        const breadCrumbs = this.breadcrumbSubject.value;
        if (breadCrumbs.length < 2) {
            return;
        }
        const updated = [
            ...breadCrumbs.slice(0, breadCrumbs.length - 1),
            { label },
        ];
        this.updateBreadcrumbs(updated);
    }
}
