import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SecurityPositionQueryResult } from '@wdx/portal/api-models';

export const PortfolioHoldingsActions = createActionGroup({
    source: 'Portfolio Holdings',
    events: {
        // Listing (Get)
        'Get Listing': props<{
            portfolioId: string;
            sortField: string;
            sortDescending: boolean;
        }>(),
        'Get Listing From API': props<{
            portfolioId: string;
            sortField: string;
            sortDescending: boolean;
            response: SecurityPositionQueryResult;
        }>(),
        'Get Listing From Store': emptyProps(),
        'Get Listing Error': props<{
            portfolioId: string;
        }>(),

        // Listing (Current Asset Class)
        'Set Asset Class': props<{ portfolioId: string; assetClass: string }>(),

        // Listing (Current Asset Class)
        'Set Sort Field': props<{
            portfolioId: string;
            sortField: string;
            sortDescending: boolean;
        }>(),
    },
});
