import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalTasksService } from './external-tasks.service';
import { ExternalTasksFacade } from './external-tasks.facade';

@NgModule({
    imports: [CommonModule],
    providers: [ExternalTasksService, ExternalTasksFacade],
})
export class ExternalTasksModule {}
