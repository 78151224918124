import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelectFieldResult } from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './client-url-segment';

@Injectable({ providedIn: 'root' })
export class ClientApiService extends BaseApiService {
    getSelection(): Observable<SelectFieldResult[]> {
        return this.get<SelectFieldResult[]>(
            `${UrlSegment.Client}/${UrlSegment.Selection}`
        );
    }

    getKeyStats(id: string): Observable<SelectFieldResult[]> {
        return this.get<SelectFieldResult[]>(
            `${UrlSegment.Client}/${id}/${UrlSegment.KeyStats}`
        );
    }
}
