import { Component, inject, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { take } from 'rxjs';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';

@Component({
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    standalone: true,
    imports: [WdxSpinnerModule],
})
export class WelcomeComponent implements OnInit {
    private authenticationService = inject(AuthenticationService);

    ngOnInit(): void {
        this.authenticationService.login().pipe(take(1)).subscribe();
    }
}
