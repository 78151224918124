import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbToast, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Toast } from '../../../shared/services/toast/toast.model';

@Component({
    selector: 'cp-toast',
    standalone: true,
    imports: [CommonModule, RouterModule, WdxIconModule, NgbToastModule],
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent extends WdxDestroyClass {
    @Input() toast!: Toast;
    @Output() hidden = new EventEmitter();

    @ViewChild(NgbToast) toastComponent!: NgbToast;

    @HostBinding('class') class =
        'd-block position-fixed top-0 start-50 translate-middle-x z-index-999 mt-4';

    get icon() {
        if (this.toast.type === 'error') {
            return 'triangle-exclamation';
        }

        if (this.toast.type === 'loader') {
            return 'circle-notch';
        }

        return 'check';
    }

    get className() {
        if (this.toast.type === 'error') {
            return 'toast-danger';
        }

        if (this.toast.type === 'loader') {
            return 'toast-warning';
        }

        return 'toast-success';
    }

    get autohide() {
        if (this.toast.type === 'loader') {
            return false;
        }

        return true;
    }

    get animation() {
        if (this.toast.type === 'loader') {
            return 'spin';
        }
        return 'none';
    }

    hide() {
        this.toastComponent.hide();
    }

    onHidden() {
        this.hidden.emit();
    }
}
