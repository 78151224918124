import { createReducer, on } from '@ngrx/store';
import { SecurityPosition } from '@wdx/portal/api-models';
import { SUCCESS_STATUS } from '../state.constants';
import { StateStatus } from '../state.model';
import { PortfolioHoldingsActions } from './portfolio-holdings.actions';

export interface PortfolioHoldingItemStatus extends StateStatus {
    currentAssetClass: string;
    assetClasses: {
        [key: string]: SecurityPosition[];
    };
    currentSortField: string;
    currentSortDescending: boolean;
}

export interface PortfolioHoldingsState {
    [key: string]: PortfolioHoldingItemStatus;
}

export const initialState = {};

export const portfolioHoldingsReducer = createReducer(
    initialState,

    on(
        PortfolioHoldingsActions.getListing,
        (state: PortfolioHoldingsState, props) => ({
            ...state,
            [props.portfolioId]: {
                ...state[props.portfolioId],
                ...(!state[props.portfolioId]?.currentAssetClass && {
                    currentAssetClass: 'All',
                }),
                ...(!state[props.portfolioId]?.currentSortField && {
                    currentSortField: 'MarketValue',
                    currentSortDescending: true,
                }),
                loading: !state[props.portfolioId]?.loaded,
            },
        })
    ),

    on(
        PortfolioHoldingsActions.getListingFromApi,
        (state: PortfolioHoldingsState, props) => {
            const grouped = props.response.results?.reduce(
                (r, v, i, a, k = v.assetClass as string) => (
                    ((r as any)[k] || ((r as any)[k] = [])).push(v), r
                ),
                {}
            );

            return {
                ...state,
                [props.portfolioId]: {
                    ...state[props.portfolioId],
                    assetClasses: {
                        All: props.response.results,
                        ...grouped,
                    },
                    ...SUCCESS_STATUS,
                },
            };
        }
    ),

    on(
        PortfolioHoldingsActions.setAssetClass,
        (state: PortfolioHoldingsState, props) => ({
            ...state,
            [props.portfolioId]: {
                ...state[props.portfolioId],
                currentAssetClass: props.assetClass,
            },
        })
    ),

    on(
        PortfolioHoldingsActions.getListingError,
        (state: PortfolioHoldingsState, props) => ({
            ...state,
            [props.portfolioId]: {
                ...state[props.portfolioId],
            },
        })
    ),

    on(
        PortfolioHoldingsActions.setSortField,
        (state: PortfolioHoldingsState, props) => ({
            ...state,
            [props.portfolioId]: {
                ...state[props.portfolioId],
                loaded: false,
                currentSortField: props.sortField,
                currentSortDescending: props.sortDescending,
            },
        })
    )
);
