import { Component, inject } from '@angular/core';
import { AppService } from './core/services/app/app.service';

@Component({
    selector: 'cp-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    private appService = inject(AppService);

    isReady$ = this.appService.isReady$;
}
