import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { SelectFieldResult, SystemEntity } from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './user-action-url-segment';

@Injectable({ providedIn: 'root' })
export class UserActionApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    view(
        entityType: SystemEntity,
        entityId: string
    ): Observable<SelectFieldResult[]> {
        return this.patch<SelectFieldResult[]>(
            `${UrlSegment.UserAction}/${UrlSegment.View}/${UrlSegment.Entity}/${entityType}/${entityId}`,
            {}
        );
    }
}
