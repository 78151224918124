<wdx-app-shell>
    <wdx-app-header>
        <cp-client-logo></cp-client-logo>
        <cp-client-switcher
            class="divider-end"
            appBarOptions
        ></cp-client-switcher>
        <cp-user-menu appBarOptions></cp-user-menu>
    </wdx-app-header>

    <wdx-app-sidebar (wdxOnCollapsed)="toggle()" [collapsed]="collapsed">
        <cp-navigation
            *ngIf="sitemap$ | ngrxPush as sitemap"
            [showLabel]="!collapsed"
            [sitemap]="sitemap"
        ></cp-navigation>
    </wdx-app-sidebar>

    <wdx-app-content>
        <cp-breadcrumbs
            wdxAppContentMeta
            class="ms-4 small d-block py-1 bg-strong w-100"
        ></cp-breadcrumbs>
        <div class="h-100">
            <ng-content></ng-content>
        </div>
    </wdx-app-content>
    <!-- mobile menu -->
    <wdx-nav-wrapper>
        <cp-navigation
            *ngIf="sitemap$ | ngrxPush as sitemap"
            [sitemap]="sitemap"
            (navClick)="closeMobileNav()"
        ></cp-navigation
    ></wdx-nav-wrapper>
</wdx-app-shell>
