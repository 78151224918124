import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { PortalTenantService } from '../../core/services/tenants/tenants.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authHttpInterceptor: AuthHttpInterceptor,
        private portalTenantService: PortalTenantService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authFreeUrls = ['system/tenant/auth0/organisationidentifier'];

        if (authFreeUrls.some((url) => req.url.includes(url))) {
            return next.handle(req);
        }

        const clonedRequest = req.clone({
            setHeaders: {
                'x-clmi-tenant': this.portalTenantService?.tenantCode,
            },
        });
        return this.authHttpInterceptor.intercept(clonedRequest, next);
    }
}
