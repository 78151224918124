import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import {
    ActivityExternalTask,
    ActivityExternalTaskQueryResult,
} from '@wdx/portal/api-models';
import { BaseApiService } from '../../libs/api-contract/base-api.service';

@Injectable()
export class ExternalTasksService extends BaseApiService {
    getTasks(): Observable<ActivityExternalTask[]> {
        return this.get<ActivityExternalTaskQueryResult>('externaltask').pipe(
            map((result) => result.results || [])
        );
    }

    submitTask(id: string): Observable<ActivityExternalTask> {
        return this.patch<ActivityExternalTask>(
            `externaltask/${id}/submit`,
            {}
        );
    }
}
