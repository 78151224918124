import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    constructor(private title: Title) {}

    private titlePrefix = '';

    initialize(prefix: string) {
        this.titlePrefix = prefix;
    }

    set(title: string | string[]) {
        let titleParts = Array.isArray(title) ? [...title] : [title];
        if (this.titlePrefix) {
            titleParts = [this.titlePrefix, ...titleParts];
        }
        this.title.setTitle(titleParts.join(' - '));
    }
}
