import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
    ActivityMessageQueryResult,
    MessageParamKey,
} from '../../libs/api-contract';
import {
    ActivityMessage,
    ActivityMessageCreate,
    ActivityMessageReply,
} from '@wdx/portal/api-models';

export const MessagesActions = createActionGroup({
    source: 'Messages',
    events: {
        // Listing (Get)
        'Get Listing': props<{
            pageNumber: number;
            paramKey?: MessageParamKey;
        }>(),
        'Get Listing From API': props<{
            response: ActivityMessageQueryResult;
            paramKey?: MessageParamKey;
        }>(),
        'Get Listing From Store': emptyProps(),
        'Get Listing Error': emptyProps(),

        // Listing (Params)
        'Set Param Key': props<{
            paramKey: MessageParamKey;
        }>(),

        // Message (Get)
        'Get Message': props<{ messageId: string }>(),
        'Get Message From API': props<{
            messageId: string;
            response: ActivityMessage;
        }>(),
        'Get Message From Store': emptyProps(),
        'Get Message Error': emptyProps(),

        // Message (Create)
        'Create New Message': props<{ body: ActivityMessageCreate }>(),
        'Create New Message Success': props<{ message: ActivityMessage }>(),
        'Create New Message Error': emptyProps(),

        // Message (Read)
        'Mark As Read': props<{ messageId: string }>(),
        'Mark As Read Success': props<{ messageId: string }>(),

        // Message (Reply)
        'Reply To Message': props<{
            messageId: string;
            body: ActivityMessageReply;
        }>(),
        'Reply To Message Success': props<{
            messageId: string;
            response: ActivityMessage;
        }>(),
        'Reply To Message Error': emptyProps(),

        // Message Thread (Get)
        'Get Thread': props<{ threadId: string; pageNumber: number }>(),
        'Get Thread From API': props<{
            threadId: string;
            response: ActivityMessageQueryResult;
        }>(),
        'Get Thread From Store': emptyProps(),
        'Get Thread Error': props<{ threadId: string }>(),
    },
});
