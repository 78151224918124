import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsAccessibilityModule from 'highcharts/modules/accessibility';
import HighchartsExportingModule from 'highcharts/modules/exporting';

@Injectable({
    providedIn: 'root',
})
export class ChartsService {
    initialize(): void {
        // Apply plugins
        HighchartsAccessibilityModule(Highcharts);
        HighchartsExportingModule(Highcharts);

        // Set global options
        Highcharts.setOptions({
            chart: {
                style: {
                    fontFamily: 'Montserrat',
                },
            },
            yAxis: {
                title: {
                    style: {
                        fontWeight: 'bold',
                    },
                },
            },
            credits: {
                enabled: false,
            },
            exporting: {
                enabled: false,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen',
                            'printChart',
                            'downloadPNG',
                            'downloadPDF',
                            'downloadSVG',
                            'downloadCSV',
                            'downloadXLS',
                        ],
                    },
                },
            },
        });
    }
}
