import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppService } from '../../../../core/services/app/app.service';
import { UserApiService } from '../../../../libs/api-contract';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'cp-client-logo',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './client-logo.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientLogoComponent implements OnInit {
    base64Logo$!: BehaviorSubject<string>;
    tenantName$!: BehaviorSubject<string>;

    constructor(
        private userApiService: UserApiService,
        private appService: AppService
    ) {}

    ngOnInit(): void {
        this.base64Logo$ = this.appService.base64Logo$;
        this.tenantName$ = this.appService.tenantName$;
    }
}
