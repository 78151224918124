import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';
import { ArticlesEffects } from './articles/articles.effects';
import { ArticlesState, articlesReducer } from './articles/articles.reducer';
import { DocumentsEffects } from './documents/documents.effects';
import {
    DocumentsState,
    documentsReducer,
} from './documents/documents.reducer';
import { MessagesEffects } from './messages/messages.effects';
import { MessagesState, messagesReducer } from './messages/messages.reducer';
import { PortfolioChartsEffects } from './portfolio-charts/portfolio-charts.effects';
import {
    PortfolioChartsState,
    portfolioChartsReducer,
} from './portfolio-charts/portfolio-charts.reducer';
import { PortfolioHoldingsEffects } from './portfolio-holdings/portfolio-holdings.effects';
import {
    PortfolioHoldingsState,
    portfolioHoldingsReducer,
} from './portfolio-holdings/portfolio-holdings.reducer';

import { PortfoliosEffects } from './portfolios/portfolios.effects';
import {
    PORTFOLIOS_FEATURE_KEY,
    PorfoliosState,
    portfoliosReducer,
} from './portfolios/portfolios.reducer';
import { TeamEffects } from './team/team.effects';
import { TeamState, teamReducer } from './team/team.reducer';
import {
    reducer as configDataReducer,
    CONFIG_DATA_FEATURE_KEY,
    State as ConfigDataState,
} from './config-data/config-data.reducer';
import {
    reducer as selectsReducer,
    SELECT_OPTIONS_FEATURE_KEY,
    SelectsState,
} from './select-options/select-options.reducer';
import { ConfigDataEffects } from './config-data/config-data.effects';
import { SelectOptionsEffects } from './select-options/select-options.effects';
import {
    reducer as externalTasksReducer,
    ExternalTaskState,
    EXTERNAL_TASKS_FEATURE_KEY,
} from './external-tasks/external-tasks.reducer';
import { ExternalTasksEffects } from './external-tasks/external-tasks.effects';
import {
    PORTFOLIO_DATA_FEATURE_KEY,
    PortfolioDataState,
    portfolioDataReducer,
} from './portfolio-data/portfolio-data.reducer';
import { PortfolioDataEffects } from './portfolio-data/portfolio-data.effects';
import { ROUTER_FEATURE_KEY } from './router/router.selectors';

export interface AppState {
    ['articles']: ArticlesState;
    ['documents']: DocumentsState;
    ['messages']: MessagesState;
    ['portfolio-charts']: PortfolioChartsState;
    ['portfolio-holdings']: PortfolioHoldingsState;
    [PORTFOLIO_DATA_FEATURE_KEY]: PortfolioDataState;
    [PORTFOLIOS_FEATURE_KEY]: PorfoliosState;
    [ROUTER_FEATURE_KEY]: RouterReducerState<any>;
    ['team']: TeamState;
    [CONFIG_DATA_FEATURE_KEY]: ConfigDataState;
    [SELECT_OPTIONS_FEATURE_KEY]: SelectsState;
    [EXTERNAL_TASKS_FEATURE_KEY]: ExternalTaskState;
}

export const effects = [
    ArticlesEffects,
    ConfigDataEffects,
    DocumentsEffects,
    MessagesEffects,
    PortfolioHoldingsEffects,
    PortfolioChartsEffects,
    PortfolioDataEffects,
    PortfoliosEffects,
    SelectOptionsEffects,
    TeamEffects,
    ExternalTasksEffects,
];

export const reducers: ActionReducerMap<AppState> = {
    ['articles']: articlesReducer,
    ['documents']: documentsReducer as any,
    ['messages']: messagesReducer,
    ['portfolio-charts']: portfolioChartsReducer,
    ['portfolio-holdings']: portfolioHoldingsReducer,
    [PORTFOLIO_DATA_FEATURE_KEY]: portfolioDataReducer,
    [PORTFOLIOS_FEATURE_KEY]: portfoliosReducer,
    [ROUTER_FEATURE_KEY]: routerReducer,
    ['team']: teamReducer,
    [CONFIG_DATA_FEATURE_KEY]: configDataReducer,
    [SELECT_OPTIONS_FEATURE_KEY]: selectsReducer,
    [EXTERNAL_TASKS_FEATURE_KEY]: externalTasksReducer,
};
