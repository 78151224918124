import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from '@wdx/shared/utils';

@Injectable({
    providedIn: 'root',
})
export class PortalTenantService {
    static TENANT_KEY = 'Tenant';
    private localStorageService = inject(LocalStorageService);

    get tenantCode(): string {
        return this.localStorageService.getStringKey(
            PortalTenantService.TENANT_KEY
        ) as string;
    }

    set tenantCode(tenantCode: string) {
        this.localStorageService.setStringKey(
            PortalTenantService.TENANT_KEY,
            tenantCode
        );
    }
}
