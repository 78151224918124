import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DocumentParamKey, DocumentQueryResult } from '../../libs/api-contract';

export const DocumentsActions = createActionGroup({
    source: 'Documents',
    events: {
        // Listing (Get)
        'Get Listing': props<{
            pageNumber: number;
            paramKey?: DocumentParamKey;
        }>(),
        'Get Listing From API': props<{
            pageNumber: number;
            paramKey?: DocumentParamKey;
            response: DocumentQueryResult;
        }>(),
        'Get Listing From Store': emptyProps(),
        'Get Listing Error': emptyProps(),

        // Listing (Params)
        'Set Param Key': props<{
            paramKey: DocumentParamKey;
        }>(),
    },
});
