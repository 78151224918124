import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectRouteParams } from '../router/router.selectors';
import { ThreadState } from './messages.model';
import { MessagesState, adapter } from './messages.reducer';

const { selectEntities, selectAll, selectIds, selectTotal } =
    adapter.getSelectors();

export const selectState = createFeatureSelector<MessagesState>('messages');

/**
 * Listing Selectors
 */

export const selectListingState = createSelector(
    selectState,
    (state: MessagesState) => state.listing
);

export const selectAllListings = createSelector(selectListingState, selectAll);

export const selectTotalListings = createSelector(
    selectListingState,
    selectTotal
);

export const selectListingIsLoading = createSelector(
    selectListingState,
    (state) => state?.loading
);

export const selectCurrentListingPage = createSelector(
    selectListingState,
    (state) => state?.currentPage
);

export const selectListingParamKey = createSelector(
    selectListingState,
    (state) => state?.paramKey
);

export const selectListingUnreadCount = createSelector(
    selectListingState,
    (state) => state?.unreadCount
);

/**
 * Message Selectors
 */

export const selectMessageState = createSelector(
    selectState,
    (state) => state.message
);

export const selectLoadedMessageIds = createSelector(
    selectMessageState,
    selectIds
);

export const selectMessageEntities = createSelector(
    selectMessageState,
    selectEntities
);

export const selectCurrentMessageId = createSelector(
    selectRouteParams,
    (state) => state?.['messageId']
);

export const selectCurrentMessage = createSelector(
    selectMessageEntities,
    selectCurrentMessageId,
    (state, messageId) => state[messageId]
);

export const selectMessageIsLoading = createSelector(
    selectMessageState,
    (state) => state.loading
);

/**
 * Thread Selectors
 */

export const selectCurrentThreadId = createSelector(
    selectCurrentMessage,
    (state) => state?.threadIdentifier
);

export const selectCurrentThreadState = createSelector(
    selectState,
    selectCurrentThreadId,
    (state, threadId) =>
        (state?.thread && state?.thread[threadId as string]) ||
        adapter.getInitialState()
);

export const selectLoadedThreadIds = createSelector(
    selectCurrentThreadState,
    selectIds
);

export const selectAllCurrentThreadItems = createSelector(
    selectCurrentThreadState,
    selectAll
);

export const selectCurrentThreadPage = createSelector(
    selectCurrentThreadState,
    (state: ThreadState) => state.currentPage
);

export const selectCurrentThreadIsLoading = createSelector(
    selectCurrentThreadState,
    (state: ThreadState) => state.loading
);
