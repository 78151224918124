<div
    class="avatar d-flex justify-content-center"
    [ngClass]="{
        'avatar-xl': size === 'xl',
        'avatar-lg': size === 'lg',
        'avatar-sm': size === 'sm'
    }"
>
    <ng-container *ngIf="avatar; else noAvatar">
        <img
            class="avatar"
            [ngClass]="{
                'avatar-xl': size === 'xl',
                'avatar-lg': size === 'lg',
                'avatar-sm': size === 'sm'
            }"
            [src]="avatar"
        />
    </ng-container>
</div>

<ng-template #noAvatar>
    <wdx-icon
        class="align-self-center"
        iconStyle="fad"
        [icon]="isLoading ? 'circle-notch' : 'user'"
        [scale]="iconScale[size]"
    ></wdx-icon>
</ng-template>
