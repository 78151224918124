import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TeamState, adapter } from './team.reducer';

const { selectAll } = adapter.getSelectors();

export const selectState = createFeatureSelector<TeamState>('team');

export const selectListingState = createSelector(
    selectState,
    (state) => state?.listing
);

export const selectListingAll = createSelector(selectListingState, selectAll);

export const selectListingIsLoading = createSelector(
    selectListingState,
    (state) => state.loading
);

export const selectListingIsLoaded = createSelector(
    selectListingState,
    (state) => state.loaded
);
