import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { ClientProduct, KeyStatResult } from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './product-url-segment';

@Injectable({ providedIn: 'root' })
export class ProductApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getProducts(): Observable<ClientProduct[]> {
        return this.get<ClientProduct[]>(`${UrlSegment.Product}`);
    }

    getKeyStats(productId: string): Observable<KeyStatResult[]> {
        return this.get<KeyStatResult[]>(
            `${UrlSegment.Product}/${productId}/${UrlSegment.KeyStats}`
        );
    }
}
