import { createFeatureSelector, createSelector } from '@ngrx/store';
import { InvestmentDataChartType } from '../../libs/api-contract';
import { PerformancePeriod } from '@wdx/portal/api-models';
import { selectRouteParams } from '../router/router.selectors';
import { PortfolioChartsState } from './portfolio-charts.reducer';

export const selectState =
    createFeatureSelector<PortfolioChartsState>('portfolio-charts');

export const selectCurrentPortfolioId = createSelector(
    selectRouteParams,
    (state) => state?.['portfolioId']
);

export const selectCurrentPortfolio = createSelector(
    selectState,
    selectCurrentPortfolioId,
    (state, portfolioId) => state[portfolioId]
);

export const selectCurrentPeriod = createSelector(
    selectCurrentPortfolio,
    (state) => state?.currentPerformancePeriod || PerformancePeriod.OneYear
);

export const selectCurrentChartType = createSelector(
    selectCurrentPortfolio,
    (state) => state?.currentChartType || InvestmentDataChartType.Value
);

export const selectCurrentChart = createSelector(
    selectCurrentPortfolio,
    selectCurrentChartType,
    (state, activeChartType) => state && state.charts[activeChartType]
);

export const selectCurrentChartResults = createSelector(
    selectCurrentChart,
    selectCurrentPeriod,
    (state, performancePeriod) =>
        state && (state as PortfolioChartsState)[performancePeriod]
);
