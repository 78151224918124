import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
    Observable,
    Subject,
    Subscription,
    debounceTime,
    fromEvent,
    merge,
    take,
    timer,
} from 'rxjs';
import { IdleModalComponent } from '../../../shared/components/idle-modal/idle-modal.component';
import { AuthenticationService } from '../authentication/authentication.service';
@Injectable({
    providedIn: 'root',
})
export class IdleService {
    idle$?: Observable<any>;
    warningTimer$?: Subscription;
    timer$?: Subscription;
    timeOutMilliSeconds!: number;
    idleSubscription?: Subscription;
    expired$ = new Subject<boolean>();
    warning$ = new Subject<boolean>();
    warningTime = 30000;

    constructor(
        private modalService: NgbModal,
        private authenticationService: AuthenticationService
    ) {}

    public startWatching(timeOutSeconds: number) {
        this.idle$ = merge(
            fromEvent(document, 'mousemove'),
            fromEvent(document, 'click'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'DOMMouseScroll'),
            fromEvent(document, 'mousewheel'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'MSPointerMove'),
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize')
        );
        this.timeOutMilliSeconds = timeOutSeconds * 1000;

        this.idleSubscription = this.idle$
            .pipe(debounceTime(100))
            .subscribe(() => {
                this.resetTimer();
            });

        this.startTimer();

        this.warning$.subscribe(() => {
            const idleModal = this.modalService.open(IdleModalComponent, {
                backdropClass: 'modal-backdrop--ngb',
                windowClass: 'modal-window--ngb',
                animation: false,
                centered: true,
            });
            idleModal.componentInstance.seconds = this.warningTime / 1000;
            this.idle$?.pipe(take(1)).subscribe(() => {
                idleModal.dismiss();
            });
        });

        this.expired$.subscribe(() => {
            this.authenticationService.logout();
        });
    }

    startTimer() {
        this.warningTimer$ = timer(
            this.timeOutMilliSeconds - this.warningTime,
            this.timeOutMilliSeconds - this.warningTime
        )
            .pipe(take(1))
            .subscribe(() => {
                this.warning$.next(true);
            });

        this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds)
            .pipe(take(1))
            .subscribe(() => {
                this.expired$.next(true);
            });
    }

    resetTimer() {
        this.warningTimer$?.unsubscribe();
        this.timer$?.unsubscribe();
        this.startTimer();
    }
}
