import { createAction, props } from '@ngrx/store';
import {
    LineChart,
    Performance,
    PerformancePeriod,
    SecurityPosition,
    SecurityTransaction,
    StandingOrder,
    ValuationSummary,
} from '@wdx/portal/api-models';
import { PortfolioPerformanceChartTypes } from '@wdx/shared/components/portfolio';

export const getPortfolioTransactions = createAction(
    '[Portfolio Data] Get Transactions Data',
    props<{
        entityId: string;
    }>()
);

export const getPortfolioTransactionsSuccess = createAction(
    '[Portfolio Data] Get Asset Transactions Data Success',
    props<{
        data: SecurityTransaction[];
        entityId: string;
    }>()
);

export const getPortfolioTransactionsError = createAction(
    '[Portfolio Data] Get Asset Transactions Data Error',
    props<{
        error: Error;
        entityId: string;
    }>()
);

export const getAssetsData = createAction(
    '[Portfolio Data] Get Assets Data',
    props<{
        entityId: string;
    }>()
);

export const getAssetsDataSuccess = createAction(
    '[Portfolio Data] Get Assets Data Success',
    props<{
        data: ValuationSummary;
        entityId: string;
    }>()
);

export const getAssetsDataError = createAction(
    '[Portfolio Data] Get Assets Data Error',
    props<{
        error: Error;
        entityId: string;
    }>()
);

export const getPositions = createAction(
    '[Portfolio Data] Get Positions',
    props<{
        entityId: string;
    }>()
);

export const getPositionsSuccess = createAction(
    '[Portfolio Data] Get Positions Success',
    props<{
        data: SecurityPosition[];
        entityId: string;
    }>()
);

export const getPositionsError = createAction(
    '[Portfolio Data] Get Positions Error',
    props<{
        error: Error;
        entityId: string;
    }>()
);

export const getStandingOrders = createAction(
    '[Portfolio Data] Get Standing Orders Data',
    props<{
        entityId: string;
    }>()
);

export const getStandingOrdersSuccess = createAction(
    '[Portfolio Data] Get Asset Standing Orders Data Success',
    props<{
        data: StandingOrder[];
        entityId: string;
    }>()
);

export const getStandingOrdersError = createAction(
    '[Portfolio Data] Get Asset Standing Orders Data Error',
    props<{
        error: Error;
        entityId: string;
    }>()
);

export const getPerformance = createAction(
    '[Portfolio Data] Get Performance',
    props<{
        entityId: string;
        performancePeriod: PerformancePeriod;
        chartType: PortfolioPerformanceChartTypes;
    }>()
);

export const getPerformanceSuccess = createAction(
    '[Portfolio Data] Get Performance Success',
    props<{
        data: LineChart;
        entityId: string;
        performancePeriod: PerformancePeriod;
        chartType: PortfolioPerformanceChartTypes;
    }>()
);

export const getPerformanceError = createAction(
    '[Portfolio Data] Get Performance Error',
    props<{
        error: Error;
        entityId: string;
        performancePeriod: PerformancePeriod;
        chartType: PortfolioPerformanceChartTypes;
    }>()
);

export const getPerformanceSummary = createAction(
    '[Portfolio Data] Get Performance Summary',
    props<{
        entityId: string;
    }>()
);

export const getPerformanceSummarySuccess = createAction(
    '[Portfolio Data] Get Performance Summary Success',
    props<{
        data: Performance;
        entityId: string;
    }>()
);

export const getPerformanceSummaryError = createAction(
    '[Portfolio Data] Get Performance Summary Error',
    props<{
        error: Error;
        entityId: string;
    }>()
);
