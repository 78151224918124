import { createAction, props } from '@ngrx/store';
import { ClientProduct } from '@wdx/portal/api-models';

export const getPortfolios = createAction('[Portfolios] Get Portfolios');

export const getPortfoliosSuccess = createAction(
    '[Portfolios] Get Portfolios Success',
    props<{
        data: ClientProduct[];
    }>()
);

export const getPortfoliosError = createAction(
    '[Portfolios] Get Portfolios Error',
    props<{
        error: Error;
    }>()
);

export const selectPortfolio = createAction(
    '[Portfolios] Select Portfolio',
    props<{
        id: string;
    }>()
);
