import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { UserService } from '../../../core/services/user/user.service';
import { AvatarComponent } from '../avatar/avatar.component';

@Component({
    selector: 'cp-user-avatar',
    standalone: true,
    imports: [CommonModule, WdxIconModule, AvatarComponent],
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent {
    private userService = inject(UserService);
    @Input() size: 'md' | 'xl' = 'md';

    avatar$ = this.userService.avatar$;
    avatarIsLoading$ = this.userService.avatarIsLoading$;
}
