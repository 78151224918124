import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductApiService } from '../../libs/api-contract';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as portfoliosActions from './portfolios.actions';

@Injectable()
export class PortfoliosEffects {
    private actions$ = inject(Actions);
    private productApiService = inject(ProductApiService);

    getPortfolios$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfoliosActions.getPortfolios),
            switchMap(() =>
                this.productApiService.getProducts().pipe(
                    map((portfolios) =>
                        portfoliosActions.getPortfoliosSuccess({
                            data: portfolios,
                        })
                    ),
                    catchError((error) =>
                        of(
                            portfoliosActions.getPortfoliosError({
                                error,
                            })
                        )
                    )
                )
            )
        )
    );
}
