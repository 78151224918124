import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SystemEntity } from '@wdx/portal/api-models';
import {
    TRANSLATION_TOAST_MESSAGE_REPLY_SENT,
    TRANSLATION_TOAST_MESSAGE_REPLYING,
    TRANSLATION_TOAST_MESSAGE_SENDING_ERROR,
    TRANSLATION_TOAST_MESSAGE_SENDING_MESSAGE,
    TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_SENT,
    TranslationsService,
} from '@wdx/shared/utils';
import { EMPTY, of } from 'rxjs';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    MessageApiService,
    UserActionApiService,
} from '../../libs/api-contract';
import { ToastService } from '../../shared/services/toast/toast.service';
import { MessagesActions } from './messages.actions';
import * as selectors from './messages.selectors';
@Injectable()
export class MessagesEffects {
    private translationsService = inject(TranslationsService);

    getListing$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.getListing),
            withLatestFrom(
                this.store$.pipe(select(selectors.selectCurrentListingPage))
            ),
            switchMap(([action, currentPage]) => {
                if (action.pageNumber <= (currentPage?.page as number)) {
                    return of(MessagesActions.getListingFromStore());
                }
                return this.messagesApiService
                    .getMessages(action.pageNumber, action.paramKey)
                    .pipe(
                        map((response) =>
                            MessagesActions.getListingFromApi({
                                response: response,
                                paramKey: action.paramKey,
                            })
                        ),
                        catchError(() => EMPTY)
                    );
            })
        )
    );

    getListingFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(MessagesActions.getListingFromStore)),
        { dispatch: false }
    );

    getMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.getMessage),
            withLatestFrom(
                this.store$.pipe(select(selectors.selectLoadedMessageIds))
            ),
            switchMap(([action, loadedMessageIds]) => {
                if ((loadedMessageIds as string[]).includes(action.messageId)) {
                    return of(MessagesActions.getMessageFromStore());
                }
                return this.messagesApiService
                    .getMessage(action.messageId)
                    .pipe(
                        map((response) =>
                            MessagesActions.getMessageFromApi({
                                messageId: action.messageId,
                                response,
                            })
                        ),
                        catchError(() => EMPTY)
                    );
            })
        )
    );

    getMessageFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(MessagesActions.getMessageFromStore)),
        { dispatch: false }
    );

    getThread$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.getThread),
            withLatestFrom(
                this.store$.pipe(select(selectors.selectCurrentThreadPage))
            ),
            switchMap(([action, currentPage]) => {
                if (action.pageNumber <= (currentPage?.page as number)) {
                    return of(MessagesActions.getThreadFromStore());
                }
                return this.messagesApiService
                    .getThread(action.threadId, action.pageNumber)
                    .pipe(
                        map((response) =>
                            MessagesActions.getThreadFromApi({
                                threadId: action.threadId,
                                response: response,
                            })
                        ),
                        catchError(() => EMPTY)
                    );
            })
        )
    );

    getThreadFromStore$ = createEffect(
        () => this.actions$.pipe(ofType(MessagesActions.getThreadFromStore)),
        { dispatch: false }
    );

    createNewMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.createNewMessage),
            tap(() => {
                this.toastService.show({
                    type: 'loader',
                    message: this.translationsService.getTranslationByKey(
                        TRANSLATION_TOAST_MESSAGE_SENDING_MESSAGE
                    ),
                });
            }),
            switchMap((action) =>
                this.messagesApiService.createNewMessage(action.body).pipe(
                    map((results) =>
                        MessagesActions.createNewMessageSuccess({
                            message: results,
                        })
                    ),
                    catchError(() =>
                        of(MessagesActions.createNewMessageError())
                    )
                )
            )
        )
    );

    addMessageSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MessagesActions.createNewMessageSuccess),
                tap((action) => {
                    this.toastService.show({
                        type: 'success',
                        message: this.translationsService.getTranslationByKey(
                            TRANSLATION_TOAST_MESSAGE_SUCCESSFULLY_SENT
                        ),
                        routerLink: ['messages', action.message.id as string],
                    });
                })
            ),
        { dispatch: false }
    );

    addMessageError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MessagesActions.createNewMessageError),
                tap(() => {
                    this.toastService.show({
                        type: 'error',
                        message: this.translationsService.getTranslationByKey(
                            TRANSLATION_TOAST_MESSAGE_SENDING_ERROR
                        ),
                    });
                })
            ),
        { dispatch: false }
    );

    setParamKey$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.setParamKey),
            map((action) =>
                MessagesActions.getListing({
                    pageNumber: 1,
                    paramKey: action.paramKey,
                })
            )
        )
    );

    replyToMessage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.replyToMessage),
            tap(() => {
                this.toastService.show({
                    type: 'loader',
                    message: this.translationsService.getTranslationByKey(
                        TRANSLATION_TOAST_MESSAGE_REPLYING
                    ),
                });
            }),
            switchMap((action) =>
                this.messagesApiService
                    .replyToMessage(action.messageId, action.body)
                    .pipe(
                        map((results) =>
                            MessagesActions.replyToMessageSuccess({
                                messageId: action.messageId,
                                response: results,
                            })
                        ),
                        catchError(() =>
                            of(MessagesActions.replyToMessageError())
                        )
                    )
            )
        )
    );

    replayToMessageSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MessagesActions.replyToMessageSuccess),
                tap(() => {
                    this.toastService.show({
                        type: 'success',
                        message: this.translationsService.getTranslationByKey(
                            TRANSLATION_TOAST_MESSAGE_REPLY_SENT
                        ),
                    });
                })
            ),
        { dispatch: false }
    );

    addThreadError$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(MessagesActions.replyToMessageError),
                tap(() => {
                    this.toastService.show({
                        type: 'error',
                        message: this.translationsService.getTranslationByKey(
                            TRANSLATION_TOAST_MESSAGE_SENDING_ERROR
                        ),
                    });
                })
            ),
        { dispatch: false }
    );

    markAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MessagesActions.markAsRead),
            mergeMap((action) =>
                this.userActionApiService
                    .view(SystemEntity.Activity, action.messageId)
                    .pipe(
                        map(() =>
                            MessagesActions.markAsReadSuccess({
                                messageId: action.messageId,
                            })
                        ),
                        catchError(() => EMPTY)
                    )
            )
        )
    );

    constructor(
        private store$: Store,
        private actions$: Actions,
        private messagesApiService: MessageApiService,
        private userActionApiService: UserActionApiService,
        private toastService: ToastService
    ) {}
}
