import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import {
    LineChart,
    Performance,
    PerformancePeriod,
    SecurityPositionQueryResult,
    SecurityTransactionQueryResult,
    StandingOrder,
    ValuationSummary,
} from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './investment-data-url-segment';
import { InvestmentDataChartType } from './investment-data.model';

@Injectable({ providedIn: 'root' })
export class InvestmentDataApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getTransactions(
        productId: string
    ): Observable<SecurityTransactionQueryResult> {
        return this.get<SecurityTransactionQueryResult>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${UrlSegment.Transactions}`,
            {
                headers: this.getPaginationHeaders({
                    pageNumber: 1,
                    pageSize: 200,
                }),
            }
        );
    }

    getPositions(
        productId: string,
        sortField: string = 'MarketValue',
        sortdescending: boolean = true
    ): Observable<SecurityPositionQueryResult> {
        return this.get<SecurityPositionQueryResult>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${UrlSegment.Positions}`,
            {
                headers: {
                    ...this.getPaginationHeaders({
                        pageNumber: 1,
                        pageSize: 200,
                    }),
                    'x-clmi-sortfield': sortField,
                    'x-clmi-sortdescending': sortdescending.toString(),
                },
            }
        );
    }

    getStandingOrders(productId: string): Observable<StandingOrder[]> {
        return this.get<StandingOrder[]>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${UrlSegment.StandingOrder}`
        );
    }

    getAssetValuations(productId: string): Observable<ValuationSummary> {
        return this.get<ValuationSummary>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${UrlSegment.Valuation}/${UrlSegment.AssetClass}`
        );
    }

    getPerformance(
        productId: string,
        performancePeriod: PerformancePeriod,
        chartType: string
    ): Observable<LineChart> {
        return this.get<LineChart>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${chartType}/${performancePeriod}/chart`
        );
    }

    getPerformanceSummary(productId: string): Observable<Performance> {
        return this.get<Performance>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${UrlSegment.Performance}/${PerformancePeriod.SinceInception}`
        );
    }

    getChart(
        productId: string,
        chartType: InvestmentDataChartType,
        performancePeriod: PerformancePeriod
    ): Observable<LineChart> {
        return this.get<LineChart>(
            `${UrlSegment.InvestmentData}/${UrlSegment.ClientProduct}/${productId}/${chartType}/${performancePeriod}/${UrlSegment.Chart}`
        );
    }
}
