import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './file-url-segment';

@Injectable({ providedIn: 'root' })
export class FileApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getFile(fileIndex: string): Observable<HttpResponse<Blob>> {
        return this.get<any>(`${UrlSegment.File}/${fileIndex}`, {
            observe: 'response',
            responseType: 'blob' as 'json',
        });
    }
}
