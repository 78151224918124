import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from '../../../core/services/authentication/authentication.service';
import { UserService } from '../../../core/services/user/user.service';
import { ExternalUser } from '@wdx/portal/api-models';
import { ThemeService, iThemeSetting } from '../../../libs/system/theme';
import { BehaviorSubject } from 'rxjs';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { MENU } from './user-menu.static.data';
import { WdxDropdownModule } from '@wdx/shared/components/wdx-dropdown';

@Component({
    selector: 'cp-user-menu',
    standalone: true,
    imports: [
        CommonModule,
        WdxIconModule,
        NgbDropdownModule,
        WdxDropdownModule,
        RouterModule,
        UserAvatarComponent,
    ],
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent implements OnInit {
    me!: ExternalUser;
    menuItems = MENU;
    currentTheme$!: BehaviorSubject<iThemeSetting>;
    iThemeSetting = iThemeSetting;

    constructor(
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private themeService: ThemeService
    ) {}

    ngOnInit(): void {
        this.me = this.userService.me;
        this.currentTheme$ = this.themeService.currentTheme$;
    }

    logout() {
        this.authenticationService.logout();
    }

    toggleTheme() {
        if (this.currentTheme$.getValue() === iThemeSetting.Dark) {
            this.themeService.setToLight();
        } else {
            this.themeService.setToDark();
        }
    }
}
