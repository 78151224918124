import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as portfolioDataActions from './portfolio-data.actions';
import { InvestmentDataApiService } from '../../libs/api-contract';

@Injectable()
export class PortfolioDataEffects {
    private actions$ = inject(Actions);
    private portfolioDataService = inject(InvestmentDataApiService);

    getTransactions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioDataActions.getPortfolioTransactions),
            switchMap((action) =>
                this.portfolioDataService.getTransactions(action.entityId).pipe(
                    map((transactions) =>
                        portfolioDataActions.getPortfolioTransactionsSuccess({
                            ...action,
                            data: transactions.results || [],
                        })
                    ),
                    catchError((error) =>
                        of(
                            portfolioDataActions.getPortfolioTransactionsError({
                                ...action,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getAssetsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioDataActions.getAssetsData),
            switchMap((action) =>
                this.portfolioDataService
                    .getAssetValuations(action.entityId)
                    .pipe(
                        map((data) =>
                            portfolioDataActions.getAssetsDataSuccess({
                                ...action,
                                data,
                            })
                        ),
                        catchError((error) =>
                            of(
                                portfolioDataActions.getAssetsDataError({
                                    ...action,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPositions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioDataActions.getPositions),
            switchMap((action) =>
                this.portfolioDataService.getPositions(action.entityId).pipe(
                    map((data) =>
                        portfolioDataActions.getPositionsSuccess({
                            ...action,
                            data: data.results || [],
                        })
                    ),
                    catchError((error) =>
                        of(
                            portfolioDataActions.getPositionsError({
                                ...action,
                                error,
                            })
                        )
                    )
                )
            )
        )
    );

    getStandingOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioDataActions.getStandingOrders),
            switchMap((action) =>
                this.portfolioDataService
                    .getStandingOrders(action.entityId)
                    .pipe(
                        map((data) =>
                            portfolioDataActions.getStandingOrdersSuccess({
                                ...action,
                                data: data || [],
                            })
                        ),
                        catchError((error) =>
                            of(
                                portfolioDataActions.getStandingOrdersError({
                                    ...action,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPerformance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioDataActions.getPerformance),
            switchMap((action) =>
                this.portfolioDataService
                    .getPerformance(
                        action.entityId,
                        action.performancePeriod,
                        action.chartType
                    )
                    .pipe(
                        map((data) =>
                            portfolioDataActions.getPerformanceSuccess({
                                ...action,
                                data: data || {},
                            })
                        ),
                        catchError((error) =>
                            of(
                                portfolioDataActions.getPerformanceError({
                                    ...action,
                                    error,
                                })
                            )
                        )
                    )
            )
        )
    );

    getPerformanceSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(portfolioDataActions.getPerformanceSummary),
            switchMap((action) =>
                this.portfolioDataService
                    .getPerformanceSummary(action.entityId)
                    .pipe(
                        map((data) =>
                            portfolioDataActions.getPerformanceSummarySuccess({
                                ...action,
                                data: data || {},
                            })
                        ),
                        catchError((error) =>
                            of(
                                portfolioDataActions.getPerformanceSummaryError(
                                    {
                                        ...action,
                                        error,
                                    }
                                )
                            )
                        )
                    )
            )
        )
    );
}
