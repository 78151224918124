import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import {
    ExternalUser,
    Locale,
    TeamListMember,
    UserTenant,
} from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './user-url-segment';

@Injectable({ providedIn: 'root' })
export class UserApiService extends BaseApiService {
    locale$: Observable<Locale>;

    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getMe(): Observable<ExternalUser> {
        return this.get<ExternalUser>(`${UrlSegment.User}/${UrlSegment.Me}`);
    }

    getLocale(): Observable<Locale> {
        if (!this.locale$) {
            this.locale$ = this.get<Locale>(
                `${UrlSegment.User}/${UrlSegment.Me}/${UrlSegment.Locale}`
            ).pipe(shareReplay(1));
        }
        return this.locale$;
    }

    getTeam(): Observable<TeamListMember[]> {
        return this.get<TeamListMember[]>(
            `${UrlSegment.User}/${UrlSegment.Me}/${UrlSegment.Team}`
        );
    }

    getTenant(): Observable<UserTenant[]> {
        return this.get<UserTenant[]>(
            `${UrlSegment.User}/${UrlSegment.Me}/${UrlSegment.Tenant}`
        );
    }

    getAvatar(): Observable<HttpResponse<Blob>> {
        return this.get(
            `${UrlSegment.User}/${UrlSegment.Me}/${UrlSegment.Avatar}`,
            {
                observe: 'response',
                responseType: 'blob' as 'json',
            }
        );
    }

    getTeamMemberAvatar(url: string): Observable<HttpResponse<Blob>> {
        return this.getRelative(url, {
            observe: 'response',
            responseType: 'blob' as 'json',
        });
    }

    addAvatar(file: File) {
        return this.upload(
            `${UrlSegment.User}/${UrlSegment.Me}/${UrlSegment.Avatar}`,
            file
        );
    }

    deleteAvatar(): Observable<HttpEvent<any>> {
        return this.delete(
            `${UrlSegment.User}/${UrlSegment.Me}/${UrlSegment.Avatar}`
        );
    }
}
