import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import {
    ActivityMessage,
    ActivityMessageCreate,
    ActivityMessageReply,
} from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './message-url-segment';
import { ActivityMessageQueryResult, MessageParamKey } from './message.model';

@Injectable({ providedIn: 'root' })
export class MessageApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getMessages(
        pageNumber: number,
        paramKey?: MessageParamKey
    ): Observable<ActivityMessageQueryResult> {
        return this.get<ActivityMessageQueryResult>(`${UrlSegment.Message}`, {
            headers: this.getPaginationHeaders({ pageNumber }),
            params: paramKey ? { [paramKey]: true } : {},
        });
    }

    getMessage(messageId: string): Observable<ActivityMessage> {
        return this.get<ActivityMessage>(`${UrlSegment.Message}/${messageId}`);
    }

    getThread(
        threadId: string,
        pageNumber: number
    ): Observable<ActivityMessageQueryResult> {
        return this.get<ActivityMessageQueryResult>(
            `${UrlSegment.Message}/${UrlSegment.Thread}/${threadId}`,
            {
                headers: this.getPaginationHeaders({ pageNumber }),
            }
        );
    }

    createNewMessage(body: ActivityMessageCreate): Observable<ActivityMessage> {
        return this.post<ActivityMessage, ActivityMessageCreate>(
            `${UrlSegment.Message}`,
            body
        );
    }

    replyToMessage(
        threadId: string,
        body: ActivityMessageReply
    ): Observable<ActivityMessage> {
        return this.post<ActivityMessage, ActivityMessageReply>(
            `${UrlSegment.Message}/${threadId}/${UrlSegment.Reply}`,
            body
        );
    }
}
