import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { Country } from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './country-url-segment';

@Injectable({ providedIn: 'root' })
export class CountryApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getCountries(): Observable<Country[]> {
        return this.get<Country[]>(`${UrlSegment.Country}`);
    }
}
