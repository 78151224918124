<wdx-nav-wrapper role="navigation">
    <wdx-nav-item *ngFor="let navigationItem of navigationItems">
        <a
            #routerLinkActive="routerLinkActive"
            [routerLink]="navigationItem.routerLink"
            [routerLinkActiveOptions]="{
                exact: !!navigationItem.routerLinkExact
            }"
            routerLinkActive="active"
            wdxNavLink
            (click)="navClicked()"
        >
            <wdx-icon
                class="me-2"
                [iconStyle]="routerLinkActive.isActive ? 'fas' : 'fal'"
                [icon]="navigationItem.icon"
                [fixedWidth]="true"
                [scale]="2"
            ></wdx-icon>

            <span *ngIf="showLabel" class="fs-5 fw-bold">{{
                navigationItem.label
            }}</span></a
        >
    </wdx-nav-item>
</wdx-nav-wrapper>
