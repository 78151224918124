import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { ActivityExternalTask } from '@wdx/portal/api-models';
import { CrudStatus } from '@wdx/shared/utils';
import { Observable, filter, map, switchMap } from 'rxjs';
import * as externalTasksActions from './external-tasks.actions';
import * as externalTasksSelectors from './external-tasks.selectors';
import { ExternalTaskState } from './external-tasks.reducer';

@Injectable()
export class ExternalTasksFacade {
    constructor(private store$: Store<ExternalTaskState>) {}

    getTasks$(): Observable<ActivityExternalTask[]> {
        return this.store$.pipe(
            select(externalTasksSelectors.selectStatus),
            switchMap((status) => {
                if (status < CrudStatus.Loading) {
                    this.loadTasks();
                }
                return this.store$.pipe(
                    select(externalTasksSelectors.selectTasks),
                    filter(Boolean),
                    map((tasks) => {
                        const sortedArray = [...tasks];
                        sortedArray.sort(
                            (a, b) => (a.order || 0) - (b.order || 0)
                        );
                        return sortedArray;
                    })
                );
            })
        );
    }

    loadTasks(): void {
        this.store$.dispatch(externalTasksActions.loadExternalTasks());
    }
}
