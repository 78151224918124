import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ActivityMessage } from '@wdx/portal/api-models';
import {
    ERROR_STATUS,
    INITIAL_STATUS,
    SUCCESS_STATUS,
} from '../state.constants';
import { MessagesActions } from './messages.actions';
import { ListingState, MessageState, ThreadState } from './messages.model';

export const adapter: EntityAdapter<ActivityMessage> =
    createEntityAdapter<ActivityMessage>({
        sortComparer: (a, b) =>
            (a.dateCreated as Date) > (b.dateCreated as Date)
                ? -1
                : (a.dateCreated as Date) < (b.dateCreated as Date)
                ? 1
                : 0,
    });

export const threadAdapter: EntityAdapter<ListingState> =
    createEntityAdapter<ListingState>();

export interface MessagesState {
    listing: ListingState;
    thread: {
        [key: string]: ThreadState;
    };
    message: MessageState;
}

export const initialState: MessagesState = {
    listing: adapter.getInitialState({
        ...INITIAL_STATUS,
        currentPage: null,
        unreadCount: null,
        paramKey: 'unreadonly',
    }) as unknown as ListingState,
    thread: {},
    message: adapter.getInitialState({
        ...INITIAL_STATUS,
    }),
};

export const messagesReducer = createReducer(
    initialState,

    /**
     * Listing
     */

    on(MessagesActions.getListing, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            loading: !state.listing?.currentPage,
        },
    })),

    on(MessagesActions.getListingFromApi, (state, props) => ({
        ...state,
        listing: adapter.addMany(
            props?.response?.results as ActivityMessage[],
            {
                ...state.listing,
                currentPage: props.response.paging,
                ...SUCCESS_STATUS,
                ...(props.paramKey === 'unreadonly' && {
                    unreadCount: props.response.paging?.totalRecords,
                }),
            }
        ) as any,
    })),

    on(MessagesActions.getListingError, (state) => ({
        ...state,
        listing: {
            ...state.listing,
            ...ERROR_STATUS,
        },
    })),

    on(MessagesActions.markAsRead, (state, props) => ({
        ...state,
        listing:
            state.listing.paramKey === 'unreadonly'
                ? adapter.removeOne(props.messageId, {
                      ...state.listing,
                      unreadCount: state.listing.unreadCount - 1,
                      currentPage: {
                          ...state.listing.currentPage,
                          totalRecords:
                              (state.listing.currentPage
                                  .totalRecords as number) - 1,
                      },
                  })
                : adapter.mapOne(
                      {
                          id: props.messageId,
                          map: (message) => ({
                              ...message,
                              isRead: true,
                          }),
                      },
                      state.listing
                  ),
    })),

    on(MessagesActions.setParamKey, (state, props) => ({
        ...state,
        listing: adapter.removeAll({
            ...state.listing,
            paramKey: props.paramKey,
            ...INITIAL_STATUS,
            currentPage: null,
        }) as any,
    })),

    /**
     * Message
     */

    on(MessagesActions.getMessage, (state, props) => ({
        ...state,
        message: {
            ...state.message,
            loading: !(state.message.ids as string[]).includes(props.messageId),
        },
    })),

    on(MessagesActions.getMessageFromApi, (state, props) => ({
        ...state,
        message: adapter.addOne(props.response, {
            ...state.message,
            ...SUCCESS_STATUS,
        }),
    })),

    on(MessagesActions.getMessageFromStore, (state) => ({
        ...state,
        message: {
            ...state.message,
            loading: false,
        },
    })),

    on(MessagesActions.getMessageError, (state) => ({
        ...state,
        message: {
            ...state.message,
            ...ERROR_STATUS,
        },
    })),

    /**
     * Thread
     */

    on(MessagesActions.getThread, (state, props) => ({
        ...state,
        thread: {
            ...state.thread,
            [props.threadId]:
                state.thread && state.thread[props.threadId]
                    ? {
                          ...state.thread[props.threadId],
                          loading: !state.thread[props.threadId]?.currentPage,
                      }
                    : adapter.getInitialState({
                          ...INITIAL_STATUS,
                          loading: true,
                          currentPage: null,
                      }),
        } as any,
    })),

    on(MessagesActions.getThreadFromApi, (state: any, props: any) => {
        return {
            ...state,
            thread: {
                ...state.thread,
                [props.threadId]: adapter.addMany(props?.response?.results, {
                    ...state.thread[props.threadId],
                    currentPage: props.response.paging,
                    ...SUCCESS_STATUS,
                }),
            },
        };
    }),

    on(MessagesActions.getThreadError, (state, props) => ({
        ...state,
        thread: {
            ...state.thread,
            [props.threadId]: {
                ...state.thread[props.threadId],
                ...ERROR_STATUS,
            },
        },
    })),

    on(MessagesActions.replyToMessageSuccess, (state: any, props: any) => ({
        ...state,
        thread: {
            ...state.thread,
            [props?.response?.threadIdentifier]: adapter.addOne(
                props.response,
                {
                    ...state.thread[props?.response?.threadIdentifier],
                    currentPage: {
                        ...state.thread[props.response.threadIdentifier]
                            .currentPage,
                        totalRecords:
                            state.thread[props.response.threadIdentifier]
                                ?.currentPage.totalRecords + 1,
                    },
                    ...SUCCESS_STATUS,
                }
            ),
        },
    })),

    /**
     * Create Message
     */

    on(MessagesActions.createNewMessageSuccess, (state, props) => ({
        ...state,
        listing: adapter.upsertOne(props.message, {
            ...state.listing,
            ...SUCCESS_STATUS,
        }),
        message: adapter.addOne(props.message, {
            ...state.message,
            ...SUCCESS_STATUS,
        }),
    }))
);
