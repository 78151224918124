import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { SelectUrlSegment } from './select-url-segment';
import { SelectFieldResult } from '@wdx/portal/api-models';
import { ContextualDataContext } from '@wdx/shared/infrastructure/form-framework';

interface ContextualSelectBody {
    lookups: any[];
    context?: ContextualDataContext[];
}

@Injectable({ providedIn: 'root' })
export class SelectApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getSelect(selectSource: string): Observable<SelectFieldResult[]> {
        const [selectKey, params] = selectSource.split('?');
        return this.get<SelectFieldResult[]>(
            `${SelectUrlSegment.Select}/${selectKey.toLowerCase()}`,
            params ? { params: new HttpParams({ fromString: params }) } : {}
        );
    }

    getContextualSelect(
        source: string,
        lookups: any[],
        context?: ContextualDataContext[]
    ): Observable<SelectFieldResult[]> {
        let body: ContextualSelectBody = { lookups };
        if (context) {
            body = { lookups, context };
        }
        return this.post<SelectFieldResult[], ContextualSelectBody>(
            `${SelectUrlSegment.Select}/${source.toLowerCase()}`,
            body
        );
    }
}
