import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { Article, ArticleQueryResult } from '@wdx/portal/api-models';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './article-url-segment';

@Injectable({ providedIn: 'root' })
export class ArticleApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getArticles(): Observable<ArticleQueryResult> {
        return this.get<ArticleQueryResult>(`${UrlSegment.Article}`);
    }

    getArticle(articleId: string): Observable<Article> {
        return this.get<Article>(`${UrlSegment.Article}/${articleId}`);
    }
}
