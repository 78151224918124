import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from '../api-config.service';
import { BaseApiService } from '../base-api.service';
import { UrlSegment } from './document-url-segment';
import { DocumentParamKey, DocumentQueryResult } from './document.model';

@Injectable({ providedIn: 'root' })
export class DocumentApiService extends BaseApiService {
    constructor(http: HttpClient, apiConfig: ApiConfigService) {
        super(http, apiConfig);
    }

    getDocuments(
        pageNumber: number,
        paramKey?: DocumentParamKey
    ): Observable<DocumentQueryResult> {
        return this.get<DocumentQueryResult>(
            `${UrlSegment.Document}/${UrlSegment.Client}`,
            {
                headers: this.getPaginationHeaders({ pageNumber }),
                params: paramKey ? { [paramKey]: true } : {},
            }
        );
    }
}
