import { Action, createReducer, on } from '@ngrx/store';
import { SelectFieldResult } from '@wdx/portal/api-models';
import * as selectsActions from './select-options.actions';
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { CrudStatus } from '@wdx/shared/utils';

export const SELECT_OPTIONS_FEATURE_KEY = 'select-options';

export interface SelectData {
    id: string;
    status: CrudStatus;
    statusString: string;
    data: SelectFieldResult[];
    error?: any;
}

export type SelectsState = EntityState<SelectData>;

export interface SelectsPartialState {
    readonly [SELECT_OPTIONS_FEATURE_KEY]: SelectsState;
}

export const selectsAdapter: EntityAdapter<SelectData> =
    createEntityAdapter<SelectData>();

export const initialSelectsState: SelectsState = selectsAdapter.getInitialState(
    {}
);

const reducerSetup = createReducer(
    initialSelectsState,
    on(
        selectsActions.getSelectOptions,
        selectsActions.getContextualSelect,
        (state, { id }) =>
            selectsAdapter.upsertOne(
                {
                    id: id,
                    data: [],
                    status: CrudStatus.Loading,
                    statusString: CrudStatus[CrudStatus.Loading],
                },
                state
            )
    ),
    on(
        selectsActions.getSelectOptionsSuccess,
        selectsActions.getContextualSelectOptionsSuccess,
        (state, { results, id }) =>
            selectsAdapter.upsertOne(
                {
                    id: id,
                    data: results,
                    status: CrudStatus.Success,
                    statusString: CrudStatus[CrudStatus.Success],
                },
                state
            )
    ),
    on(
        selectsActions.getSelectOptionsFailure,
        selectsActions.getContextualSelectOptionsFailure,
        (state, { error, id }) =>
            selectsAdapter.upsertOne(
                {
                    id: id,
                    error,
                    data: [],
                    status: CrudStatus.Error,
                    statusString: CrudStatus[CrudStatus.Error],
                },
                state
            )
    )
);

export function reducer(state: SelectsState | undefined, action: Action) {
    return reducerSetup(state, action);
}
