<ng-container *ngIf="isReady$ | ngrxPush; else loading">
    <wdx-toast
        class="d-block toast-container z-index-999"
        aria-live="polite"
        aria-atomic="true"
    ></wdx-toast>
    <router-outlet></router-outlet>
</ng-container>

<ng-template #loading>
    <div class="bg-body position-fixed h-100 w-100 z-index-999">
        <wdx-spinner class="absolute-center"></wdx-spinner>
    </div>
</ng-template>
