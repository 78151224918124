import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { ConfigService } from '../config/config.service';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private auth0Service: AuthService,
        private configService: ConfigService
    ) {}

    logout() {
        this.auth0Service.logout({
            client_id: this.configService.getConfig().AuthenticationClientId,
            returnTo: `${window.location.origin}`,
        });
    }
}
