import { Action, createReducer, on } from '@ngrx/store';
import { ClientProduct } from '@wdx/portal/api-models';
import * as portfoliosActions from './portfolios.actions';
import { CrudStatus, setCrudStatus } from '@wdx/shared/utils';

export const PORTFOLIOS_FEATURE_KEY = 'portfolios';

export interface PorfoliosState {
    data?: ClientProduct[];
    status: CrudStatus;
    statusString: string;
    error?: any;
}

export const initialState: PorfoliosState = {
    ...setCrudStatus(CrudStatus.Initial),
};

export const reducerSetup = createReducer(
    initialState,

    on(portfoliosActions.getPortfolios, (state) => ({
        ...state,
        data: undefined,
        error: undefined,
        selectedPortfolio: undefined,
        ...setCrudStatus(CrudStatus.Loading),
    })),

    on(portfoliosActions.getPortfoliosSuccess, (state, props) => ({
        ...state,
        data: props.data,
        error: undefined,
        ...setCrudStatus(CrudStatus.Success),
    })),

    on(portfoliosActions.getPortfoliosError, (state, props) => ({
        ...state,
        data: undefined,
        selectedPortfolio: undefined,
        error: props.error,
        ...setCrudStatus(CrudStatus.Error),
    }))
);

export function portfoliosReducer(
    state: PorfoliosState | undefined,
    action: Action
) {
    return reducerSetup(state, action);
}
