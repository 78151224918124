import { inject, Inject, Injectable } from '@angular/core';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { FeaturesService } from '@wdx/shared/utils';
import { APP_CONFIG, Config } from '../../../app.config';
import { ApiConfigService } from '../../../libs/api-contract/api-config.service';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private authClientConfig = inject(AuthClientConfig);
    private apiConfigService = inject(ApiConfigService);
    private featuresService = inject(FeaturesService);

    config: Config;

    constructor(@Inject(APP_CONFIG) config: Config) {
        this.config = config;
    }

    initialize() {
        this.featuresService.set({ addressLookupEnabled: true });
        return new Promise<void>((resolve) => {
            this.configureAuthentication();
            this.configureApi();

            resolve();
        });
    }

    configureAuthentication() {
        this.authClientConfig.set({
            domain: this.config.AuthenticationResource,
            clientId: this.config.AuthenticationClientId,
            audience: this.config.AuthenticationAudience,
            scope: 'access:user',
            useRefreshTokens: true,
            authorizationParams: {
                redirect_uri: window.location.origin,
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: `${this.config.API_BASE}/*`,
                        tokenOptions: {
                            authorizationParams: {
                                audience: this.config.AuthenticationAudience,
                                scope: 'access:user',
                            },
                        },
                    },
                ],
            },
        });
    }

    configureApi() {
        this.apiConfigService.set({
            apiBaseUrl: this.config.API_BASE as string,
        });
    }

    getConfig() {
        return this.config;
    }
}
