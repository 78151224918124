import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Article } from '@wdx/portal/api-models';

export const ArticlesActions = createActionGroup({
    source: 'Articles',
    events: {
        // Listing (Get)
        'Get Listing': emptyProps(),
        'Get Listing From API': props<{ response: Article[] }>(),
        'Get Listing From Store': emptyProps(),
        'Get Listing Error': emptyProps(),

        // Article (Get)
        'Get Article': props<{ articleId: string }>(),
        'Get Article From API': props<{
            articleId: string;
            response: Article;
        }>(),
        'Get Article From Store': emptyProps(),
        'Get Article Error': emptyProps(),
    },
});
