import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { InvestmentDataApiService } from '../../libs/api-contract/investment-data/investment-data.service';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { PortfolioHoldingsActions } from './portfolio-holdings.actions';
import * as selectors from './portfolio-holdings.selectors';

@Injectable()
export class PortfolioHoldingsEffects {
    getPortfolioHoldings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioHoldingsActions.getListing),
            withLatestFrom(
                this.store$.pipe(select(selectors.selectCurrentIsLoaded))
            ),
            switchMap(([action, loaded]) => {
                if (loaded) {
                    return of(PortfolioHoldingsActions.getListingFromStore());
                }
                return this.investmentDataApiService
                    .getPositions(
                        action.portfolioId,
                        action.sortField,
                        action.sortDescending
                    )
                    .pipe(
                        map((response) =>
                            PortfolioHoldingsActions.getListingFromApi({
                                portfolioId: action.portfolioId,
                                response,
                                sortField: action.sortField,
                                sortDescending: action.sortDescending,
                            })
                        ),
                        catchError(() => EMPTY)
                    );
            })
        )
    );

    getListingFromStore$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PortfolioHoldingsActions.getListingFromStore)
            ),
        { dispatch: false }
    );

    setSortField$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioHoldingsActions.setSortField),
            map((action) =>
                PortfolioHoldingsActions.getListing({
                    portfolioId: action.portfolioId,
                    sortField: action.sortField,
                    sortDescending: action.sortDescending,
                })
            )
        )
    );

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private investmentDataApiService: InvestmentDataApiService
    ) {}
}
