import { createAction, props } from '@ngrx/store';
import { Country, Currency, Locale } from '@wdx/portal/api-models';

export const loadLocale = createAction('[ConfigData] Load Locale');

export const loadLocaleSuccess = createAction(
    '[ConfigData] Load Locale Success',
    props<{ locale: Locale }>()
);

export const loadLocaleFailure = createAction(
    '[ConfigData] Load Locale Failure'
);

export const loadCurrencies = createAction('[ConfigData] Load Currencies');

export const loadCurrenciesSuccess = createAction(
    '[ConfigData] Load Currencies Success',
    props<{ currencies: Currency[] }>()
);

export const loadCurrenciesFailure = createAction(
    '[ConfigData] Load Currencies Failure'
);

export const loadCountries = createAction('[ConfigData] Load Countries');

export const loadCountriesSuccess = createAction(
    '[ConfigData] Load Countries Success',
    props<{ countries: Country[] }>()
);

export const loadCountriesFailure = createAction(
    '[ConfigData] Load Countries Failure'
);
