import { createAction, props } from '@ngrx/store';
import { ActivityExternalTask } from '@wdx/portal/api-models';

export const loadExternalTasks = createAction(
    '[ExternalTasks] Load ExternalTasks'
);

export const loadExternalTasksSuccess = createAction(
    '[ExternalTasks] Load ExternalTasks Success',
    props<{ tasks: ActivityExternalTask[] }>()
);

export const loadExternalTasksFailure = createAction(
    '[ExternalTasks] Load ExternalTasks Failure',
    props<{ error: any }>()
);
