import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { InvestmentDataChartType } from '../../libs/api-contract';
import { LineChart, PerformancePeriod } from '@wdx/portal/api-models';

export const PortfolioChartsActions = createActionGroup({
    source: 'Portfolio Charts',
    events: {
        'Get Chart': props<{
            portfolioId: string;
            chartType: InvestmentDataChartType;
            performancePeriod: PerformancePeriod;
        }>(),
        'Get Chart From API': props<{
            portfolioId: string;
            chartType: InvestmentDataChartType;
            performancePeriod: PerformancePeriod;
            response: LineChart;
        }>(),
        'Get Chart From Store': emptyProps(),
        'Get Chart Error': props<{
            portfolioId: string;
        }>(),

        'Set Current Performance Period': props<{
            portfolioId: string;
            performancePeriod: PerformancePeriod;
        }>(),

        'Set Current Chart Type': props<{
            portfolioId: string;
            chartType: InvestmentDataChartType;
        }>(),
    },
});
