import { InjectionToken } from '@angular/core';

export interface Config {
    API_BASE?: string;
    AuthenticationAudience: string;
    AuthenticationClientId: string;
    AuthenticationResource: string;
}

export const APP_CONFIG = new InjectionToken<Config>('APP_CONFIG');
