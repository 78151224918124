import { Injectable } from '@angular/core';
import { Colour, Palette, Theme } from '@wdx/portal/api-models';
import { LocalStorageService } from '../../../libs/utils/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';
import { CLMI_DARK_THEME_VARS } from './clmi-dark-theme-vars.constants';
import { DARK_THEME_VARS } from './dark-theme-vars.constants';
import { iThemeSetting } from './theme-setting.enum';
import { IThemeService } from '@wdx/shared/utils';

@Injectable()
export class ThemeService implements IThemeService {
    private VARIABLE_PREFIX = 'bs-';
    private LOCAL_STORAGE_KEY = 'ThemeSetting';
    private _theme!: Theme;
    private darkThemeCSSVariables: string;

    themeCSSVariables!: string;
    themeSetting!: iThemeSetting;
    currentTheme$ = new BehaviorSubject<iThemeSetting>(iThemeSetting.Light);

    constructor(private localStorageService: LocalStorageService) {
        this.getThemeSettingFromLocalStorage();

        this.darkThemeCSSVariables = [
            this.setKeyValueObjectAsCSSVariables(DARK_THEME_VARS),
            this.setKeyValueObjectAsCSSVariables(CLMI_DARK_THEME_VARS, 'clmi-'),
        ].join(' ');
    }

    set(theme: Theme) {
        this._theme = theme;
        this.setThemeAsCSSVariables(theme);
        this.updateThemeSetting(this.themeSetting);
    }

    get theme() {
        return this._theme;
    }

    getThemeValue(themeVariable: string): string {
        return window
            .getComputedStyle(document.documentElement, null)
            .getPropertyValue(`--bs-${themeVariable}`);
    }

    setToLight(): void {
        this.themeSetting = iThemeSetting.Light;
        this.updateCurrentTheme(iThemeSetting.Light);
        this.applyThemeToDocument(false);
        this.updateLocalStorage(iThemeSetting.Light);
    }

    setToDark(): void {
        this.updateCurrentTheme(iThemeSetting.Dark);
        this.themeSetting = iThemeSetting.Dark;
        this.applyThemeToDocument(true);
        this.updateLocalStorage(iThemeSetting.Dark);
    }

    private updateThemeSetting(themeSetting: iThemeSetting): void {
        this.themeSetting = themeSetting;
        switch (this.themeSetting) {
            case iThemeSetting.Light:
                this.setToLight();
                break;
            case iThemeSetting.Dark:
                this.setToDark();
                break;
        }
    }

    private setThemeAsCSSVariables(theme: Theme): void {
        const cssVariables = [
            this.setPaletteAsCSSVariables(theme.base as Palette),
            this.setPaletteAsCSSVariables(theme.light as Palette, 'light'),
            this.setPaletteAsCSSVariables(theme.dark as Palette, 'dark'),
        ];
        this.themeCSSVariables = cssVariables.join(' ');

        this.applyThemeToDocument(false);
    }

    private setPaletteAsCSSVariables(
        palette: Palette,
        suffix?: string
    ): string {
        if (!palette) {
            return '';
        }
        return Object.keys(palette)
            .map((color) =>
                this.setColorRgb(
                    color,
                    suffix as string,
                    (palette as any)[color] as Colour
                )
            )
            .join(' ');
    }

    private setColorRgb(color: string, suffix: string, rgb: Colour): string {
        if (!rgb) {
            return '';
        }
        const variable = `--${this.VARIABLE_PREFIX}${color}${
            suffix ? '-' + suffix : ''
        }`;
        return [
            `${variable}-red: ${rgb.r};`,
            `${variable}-green: ${rgb.g};`,
            `${variable}-blue: ${rgb.b};`,
        ].join(' ');
    }

    private getThemeSettingFromLocalStorage(): void {
        this.themeSetting =
            (iThemeSetting as any)[
                this.localStorageService.getStringKey(this.LOCAL_STORAGE_KEY)
            ] || iThemeSetting.Light;
    }

    private updateLocalStorage(themeSetting: iThemeSetting): void {
        this.localStorageService.setStringKey(
            this.LOCAL_STORAGE_KEY,
            themeSetting
        );
    }

    private applyThemeToDocument(darkTheme: boolean): void {
        const theme = [
            this.themeCSSVariables,
            ...(darkTheme ? [this.darkThemeCSSVariables] : []),
        ].join(' ');
        document.documentElement.setAttribute('style', theme);
        this.updateCurrentTheme(
            darkTheme ? iThemeSetting.Dark : iThemeSetting.Light
        );
    }

    private setKeyValueObjectAsCSSVariables(
        obj: any,
        keyPrefix?: string,
        valuePrefix?: string
    ): string {
        if (!obj) {
            return '';
        }
        return Object.keys(obj)
            .map(
                (color) =>
                    `--${keyPrefix || this.VARIABLE_PREFIX}${color}: var(--${
                        valuePrefix || this.VARIABLE_PREFIX
                    }${obj[color]});`
            )
            .join(' ');
    }

    private updateCurrentTheme(theme: iThemeSetting): void {
        this.currentTheme$.next(theme);
    }
}
