import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfigService } from './api-config.service';
import { PaginationOptions } from './api-pagination.model';

@Injectable()
export class BaseApiService {
    protected get apiBaseUrl(): string {
        return this.apiConfigService.get().apiBaseUrl;
    }

    constructor(
        private http: HttpClient,
        private apiConfigService: ApiConfigService
    ) {}

    /**
     * Initialtes a get request using the provided apiBaseUrl
     */
    protected get<Response>(path: string, options = {}): Observable<Response> {
        return this.http.get<Response>(`${this.apiBaseUrl}/${path}`, options);
    }

    /**
     * Initialtes a get request relative to the app root
     */
    protected getRelative<Response>(
        path: string,
        options = {}
    ): Observable<Response> {
        return this.http.get<Response>(path, options);
    }

    protected post<Response, Request>(
        path: string,
        body: Request,
        options = {}
    ): Observable<Response> {
        return this.http.post<Response>(
            `${this.apiBaseUrl}/${path}`,
            body,
            options
        );
    }

    protected put<Response, Request = any>(
        path: string,
        body: Request,
        options = {}
    ): Observable<Response> {
        return this.http.put<Response>(
            `${this.apiBaseUrl}/${path}`,
            body,
            options
        );
    }

    protected patch<Response, Request = any>(
        path: string,
        body: Request,
        options = {}
    ): Observable<Response> {
        return this.http.patch<Response>(
            `${this.apiBaseUrl}/${path}`,
            body,
            options
        );
    }

    protected delete<Response>(path: string): Observable<Response> {
        return this.http.delete<Response>(`${this.apiBaseUrl}/${path}`);
    }

    protected upload(path: string, file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();

        formData.append(file.name, file);

        const req = new HttpRequest(
            'PUT',
            `${this.apiBaseUrl}/${path}`,
            formData,
            {
                reportProgress: true,
            }
        );
        return this.http.request(req);
    }

    protected getPaginationHeaders(paginationOptions: PaginationOptions) {
        {
            return {
                ...(paginationOptions?.pageNumber
                    ? {
                          'x-clmi-pagenumber': String(
                              paginationOptions.pageNumber
                          ),
                      }
                    : {}),
                ...(paginationOptions?.pageSize
                    ? { 'x-clmi-pagesize': String(paginationOptions.pageSize) }
                    : {}),
                ...(paginationOptions?.sortField
                    ? {
                          'x-clmi-sortfield': String(
                              paginationOptions.sortField
                          ),
                      }
                    : {}),
                ...(paginationOptions?.sortDescending
                    ? {
                          'x-clmi-sortdescending': String(
                              paginationOptions.sortDescending
                          ),
                      }
                    : {}),
            };
        }
    }
}
