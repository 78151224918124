<ngb-toast
    *ngIf="toast"
    [autohide]="autohide"
    [class]="className"
    (hidden)="onHidden()"
>
    <div class="d-flex align-items-center">
        <a *ngIf="toast.routerLink" [routerLink]="toast.routerLink">{{
            toast.message
        }}</a>
        <div *ngIf="!toast.routerLink">{{ toast.message }}</div>
        <wdx-icon
            class="ms-auto"
            [fixedWidth]="true"
            [icon]="icon"
            size="lg"
        ></wdx-icon></div
></ngb-toast>
