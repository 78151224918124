import {
    ApplicationRef,
    ComponentRef,
    EnvironmentInjector,
    Injectable,
    createComponent,
} from '@angular/core';
import { ToastComponent } from '../../../shared/components/toast/toast.component';
import { take } from 'rxjs';
import { Toast } from './toast.model';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    activeToastRef!: ComponentRef<ToastComponent>;

    constructor(
        private appRef: ApplicationRef,
        private injector: EnvironmentInjector
    ) {}

    show(toast: Toast) {
        if (this.activeToastRef) {
            this.activeToastRef.instance.hide();
        }

        const toastRef = createComponent(ToastComponent, {
            environmentInjector: this.injector,
        });

        toastRef.setInput('toast', toast);

        document.body.appendChild(toastRef.location.nativeElement);

        this.appRef.attachView(toastRef.hostView);

        toastRef.instance.hidden
            .pipe(take(1))
            .subscribe(() => this.appRef.detachView(toastRef.hostView));

        this.activeToastRef = toastRef;
    }
}
