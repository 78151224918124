export enum UrlSegment {
    InvestmentData = 'investmentdata',
    ClientProduct = 'clientproduct',
    Transactions = 'transactions',
    Positions = 'positions',
    Valuation = 'valuation',
    AssetClass = 'assetclass',
    Performance = 'performance',
    StandingOrder = 'standingorder',
    Chart = 'chart',
}
