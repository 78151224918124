import { createReducer, on } from '@ngrx/store';
import * as ConfigDataActions from './config-data.actions';
import { CrudStatus } from '@wdx/shared/utils';
import { Country, Currency, Locale } from '@wdx/portal/api-models';

export const CONFIG_DATA_FEATURE_KEY = 'configData';

export interface State {
    locale: { status: CrudStatus; data: Locale };
    countries: { status: CrudStatus; data: Country[] };
    currencies: { status: CrudStatus; data: Currency[] };
}

export interface ConfigDataSliceState {
    readonly [CONFIG_DATA_FEATURE_KEY]: State;
}

export const initialState: State = {
    locale: { status: CrudStatus.Initial, data: {} },
    countries: { status: CrudStatus.Initial, data: [] },
    currencies: { status: CrudStatus.Initial, data: [] },
};

export const reducer = createReducer(
    initialState,

    on(ConfigDataActions.loadCountries, (state) => ({
        ...state,
        countries: {
            status: CrudStatus.Loading,
            data: [],
        },
    })),
    on(ConfigDataActions.loadCountriesSuccess, (state, { countries }) => ({
        ...state,
        countries: {
            status: CrudStatus.Success,
            data: [...countries],
        },
    })),
    on(ConfigDataActions.loadCountriesFailure, (state) => ({
        ...state,
        countries: {
            status: CrudStatus.Error,
            data: [],
        },
    })),
    on(ConfigDataActions.loadCurrencies, (state) => ({
        ...state,
        currencies: {
            status: CrudStatus.Loading,
            data: [],
        },
    })),
    on(ConfigDataActions.loadCurrenciesSuccess, (state, { currencies }) => ({
        ...state,
        currencies: {
            status: CrudStatus.Success,
            data: [...currencies],
        },
    })),
    on(ConfigDataActions.loadCurrenciesFailure, (state) => ({
        ...state,
        currencies: {
            status: CrudStatus.Error,
            data: [],
        },
    })),
    on(ConfigDataActions.loadLocale, (state) => ({
        ...state,
        locale: {
            status: CrudStatus.Loading,
            data: {},
        },
    })),
    on(ConfigDataActions.loadLocaleSuccess, (state, { locale }) => ({
        ...state,
        locale: {
            status: CrudStatus.Success,
            data: { ...locale },
        },
    })),
    on(ConfigDataActions.loadLocaleFailure, (state) => ({
        ...state,
        locale: {
            status: CrudStatus.Error,
            data: {},
        },
    }))
);
