import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';

@Component({
    selector: 'cp-avatar',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
    @Input() size: 'sm' | 'md' | 'lg' | 'xl' = 'md';
    @Input() isLoading?: boolean | null;
    @Input() avatar?: string | null;

    public iconScale = {
        sm: 0.75,
        md: 1,
        lg: 2,
        xl: 3,
    };
}
