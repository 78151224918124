import { Injectable } from '@angular/core';
import { BehaviorSubject, from, takeWhile } from 'rxjs';
import { ExternalUser, Locale } from '@wdx/portal/api-models';
import { PartyAvatarService } from '../../../shared/services/party-avatar/party-avatar.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    avatar$ = new BehaviorSubject<string>('');
    avatarIsLoading$ = new BehaviorSubject<boolean>(false);

    private _me?: ExternalUser;
    private _locale?: Locale;

    set me(me: ExternalUser) {
        this._me = me;
    }

    get me() {
        return this._me as ExternalUser;
    }

    set locale(locale: Locale) {
        this._locale = locale;
    }

    get locale() {
        return this._locale as Locale;
    }

    constructor(private partyAvatarService: PartyAvatarService) {}

    set(me: ExternalUser, locale: Locale) {
        this.me = me;
        this.locale = locale;
        this.loadAvatar();
    }

    loadAvatar() {
        this.avatarIsLoading$.next(true);
        from(this.partyAvatarService.getBase64Avatar(this.me?.id as string))
            .pipe(takeWhile((avatar) => avatar === null || Boolean(avatar)))
            .subscribe((avatar) => {
                this.avatarIsLoading$.next(false);
                this.avatar$.next(avatar);
            });
    }

    clearAvatar() {
        this.partyAvatarService.clearFromCache(this.me?.id as string);
        this.avatar$.next('');
    }
}
