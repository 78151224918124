import { createReducer, on } from '@ngrx/store';
import { InvestmentDataChartType } from '../../libs/api-contract';
import { LineChart, PerformancePeriod } from '@wdx/portal/api-models';
import { PortfolioChartsActions } from './portfolio-charts.actions';

export interface PortfolioChartsState {
    [key: string]: {
        charts: {
            [key: string]: LineChart;
        };
        currentPerformancePeriod: PerformancePeriod;
        currentChartType: InvestmentDataChartType;
    };
}

export const initialState = {};

export const portfolioChartsReducer = createReducer(
    initialState,

    on(PortfolioChartsActions.getChart, (state) => ({
        ...state,
    })),

    on(
        PortfolioChartsActions.getChartFromApi,
        (state: PortfolioChartsState, props) => {
            const portfolioObject = state[props.portfolioId];
            return {
                ...state,
                [props.portfolioId]: {
                    ...portfolioObject,
                    charts: {
                        ...portfolioObject?.charts,
                        [props.chartType]: {
                            ...portfolioObject?.charts[props.chartType],
                            [props.performancePeriod]: props.response,
                        },
                    },
                },
            };
        }
    ),

    on(
        PortfolioChartsActions.setCurrentPerformancePeriod,
        (state: PortfolioChartsState, props) => ({
            ...state,
            [props.portfolioId]: {
                ...state[props.portfolioId],
                currentPerformancePeriod: props.performancePeriod,
            },
        })
    ),

    on(
        PortfolioChartsActions.setCurrentChartType,
        (state: PortfolioChartsState, props) => ({
            ...state,
            [props.portfolioId]: {
                ...state[props.portfolioId],
                currentChartType: props.chartType,
            },
        })
    )
);
