import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SitemapEntry } from '@wdx/portal/api-models';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { WdxNavModule } from '@wdx/shared/components/wdx-nav';
import { NavigationMenu } from './navigation.model';
import { MENU } from './navigation.static.data';

@Component({
    selector: 'cp-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    standalone: true,
    imports: [CommonModule, RouterModule, WdxIconModule, WdxNavModule],
})
export class NavigationComponent {
    @Input() showLabel = true;
    @Input() set sitemap(sitemap: SitemapEntry[]) {
        this.navigationItems = sitemap
            .map((sItem) => {
                const menuItem = MENU.find(
                    (mItem) => mItem.sitemapCode === sItem.code
                );
                if (menuItem) {
                    return {
                        ...menuItem,
                        label: sItem.displayName?.value || menuItem.label,
                        icon: sItem.icon || menuItem.icon,
                        visible: true,
                    } as NavigationMenu;
                }
                return undefined;
            })
            .filter(Boolean) as NavigationMenu[];
    }
    @Output() navClick = new EventEmitter<void>();

    public navigationItems: NavigationMenu[] = [];

    navClicked() {
        this.navClick.emit();
    }
}
