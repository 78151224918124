import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { InvestmentDataApiService } from '../../libs/api-contract/investment-data/investment-data.service';
import { EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { PortfolioChartsActions } from './portfolio-charts.actions';
import * as selectors from './portfolio-charts.selectors';

@Injectable()
export class PortfolioChartsEffects {
    getValueChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(PortfolioChartsActions.getChart),
            withLatestFrom(
                this.store$.pipe(select(selectors.selectCurrentChart))
            ),
            switchMap(([action, chart]) => {
                if (chart && (chart as any)[action.performancePeriod]) {
                    return of(PortfolioChartsActions.getChartFromStore());
                }
                return this.investmentDataApiService
                    .getChart(
                        action.portfolioId,
                        action.chartType,
                        action.performancePeriod
                    )
                    .pipe(
                        map((response) =>
                            PortfolioChartsActions.getChartFromApi({
                                portfolioId: action.portfolioId,
                                chartType: action.chartType,
                                performancePeriod: action.performancePeriod,
                                response,
                            })
                        ),
                        catchError(() => EMPTY)
                    );
            })
        )
    );

    getChartFromStore$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(PortfolioChartsActions.getChartFromStore)
            ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private investmentDataApiService: InvestmentDataApiService
    ) {}
}
