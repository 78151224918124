import { createReducer, on } from '@ngrx/store';
import { ActivityExternalTask } from '@wdx/portal/api-models';
import { CrudStatus } from '@wdx/shared/utils';
import * as externalTasksActions from './external-tasks.actions';

export const EXTERNAL_TASKS_FEATURE_KEY = 'externalTasks';

export interface ExternalTaskState {
    status: CrudStatus;
    statusString: string;
    tasks?: ActivityExternalTask[];
    error?: any;
}

export const initialState: ExternalTaskState = {
    status: CrudStatus.Initial,
    statusString: CrudStatus[CrudStatus.Initial],
    tasks: undefined,
};

export const reducer = createReducer(
    initialState,
    on(externalTasksActions.loadExternalTasks, () => ({
        status: CrudStatus.Loading,
        statusString: CrudStatus[CrudStatus.Loading],
        tasks: undefined,
        error: undefined,
    })),
    on(externalTasksActions.loadExternalTasksSuccess, (_, { tasks }) => ({
        status: CrudStatus.Success,
        statusString: CrudStatus[CrudStatus.Success],
        tasks,
        error: undefined,
    })),
    on(externalTasksActions.loadExternalTasksFailure, (state, { error }) => ({
        ...state,
        status: CrudStatus.Error,
        statusString: CrudStatus[CrudStatus.Error],
        error,
    }))
);
