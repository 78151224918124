import { StateStatus } from './state.model';

export const INITIAL_STATUS: StateStatus = {
    loading: false,
    loaded: false,
    error: false,
};

export const LOADING_STATUS: StateStatus = {
    loading: true,
    error: false,
};

export const SUCCESS_STATUS: StateStatus = {
    loading: false,
    loaded: true,
    error: false,
};

export const ERROR_STATUS: StateStatus = {
    loading: false,
    error: true,
    loaded: false,
};
