import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    ExternalTaskState,
    EXTERNAL_TASKS_FEATURE_KEY,
} from './external-tasks.reducer';

export const selectExternalTasksState =
    createFeatureSelector<ExternalTaskState>(EXTERNAL_TASKS_FEATURE_KEY);

export const selectTasks = createSelector(
    selectExternalTasksState,
    (state) => state.tasks
);

export const selectStatus = createSelector(
    selectExternalTasksState,
    (state) => state.status
);
