import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WdxIconModule } from '@wdx/shared/components/wdx-icon';
import { Subscription, interval, takeWhile } from 'rxjs';

@Component({
    selector: 'cp-idle-modal',
    standalone: true,
    imports: [CommonModule, WdxIconModule],
    templateUrl: './idle-modal.component.html',
    styleUrls: ['./idle-modal.component.scss'],
})
export class IdleModalComponent implements OnInit, OnDestroy {
    @Input() seconds!: number;

    subscription = new Subscription();

    ngOnInit(): void {
        const source = interval(1000);
        this.subscription = source
            .pipe(takeWhile(() => this.seconds > 0))
            .subscribe(() => (this.seconds = this.seconds - 1));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
