import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {
    EMPTY,
    Observable,
    catchError,
    map,
    tap,
    switchMap,
    filter,
    forkJoin,
} from 'rxjs';
import {
    SystemApiService,
    ClientApiService,
    UserApiService,
} from '../../libs/api-contract';
import { ThemeService } from '../../libs/system/theme';
import { AppService } from '../services/app/app.service';
import { ClientsService } from '../services/clients/clients.service';
import { IdleService } from '../services/idle/idle.service';
import { UserService } from '../services/user/user.service';
import { SitemapApiService } from '../../libs/api-contract/sitemap/sitemap.service';
import { AuthService } from '@auth0/auth0-angular';
import { FaviconService } from '@wdx/shared/utils';

@Injectable({
    providedIn: 'root',
})
export class AppGuard implements CanActivate {
    private sitemapService = inject(SitemapApiService);
    private router = inject(Router);
    private idleService = inject(IdleService);
    private systemApiService = inject(SystemApiService);
    private clientApiService = inject(ClientApiService);
    private userApiService = inject(UserApiService);
    private clientsService = inject(ClientsService);
    private themeService = inject(ThemeService);
    private userService = inject(UserService);
    private appService = inject(AppService);
    private auth = inject(AuthService);
    private faviconService = inject(FaviconService);

    canActivate(): Observable<boolean> {
        this.appService.isReady$.next(false);

        return this.auth.isLoading$.pipe(
            filter((loading) => loading === false),
            switchMap(() => {
                return this.clientApiService.getSelection().pipe(
                    tap((clients) => {
                        this.clientsService.set(clients);
                    }),
                    switchMap(() =>
                        forkJoin({
                            _: this.sitemapService.getSitemap(),
                            me: this.userApiService.getMe(),
                            locale: this.userApiService.getLocale(),
                            theme: this.systemApiService.getTheme(),
                        }).pipe(
                            tap((res) => {
                                // Set up eagerly required data
                                this.userService.set(res.me, res.locale);
                                this.themeService.set(res.theme);

                                // Setup idle timeout (the time should come from a settings file eventually)
                                this.idleService.startWatching(86400);

                                // Notify app is ready
                                this.appService.isReady$.next(true);

                                // Set dynamic favicon
                                this.faviconService.loadFavicon();
                            }),
                            map(() => true),
                            catchError((error) => {
                                console.error(error);
                                this.router.navigate(['/error']);
                                this.appService.isReady$.next(true);
                                return EMPTY;
                            })
                        )
                    )
                );
            })
        );
    }
}
