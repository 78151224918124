import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppService } from '../../../core/services/app/app.service';
import { FileApiService, UserApiService } from '../../../libs/api-contract';
import { ThemeService } from '../../../libs/system/theme';
import { filter, take } from 'rxjs';
import { AppShellComponent } from '../app-shell/app-shell.component';
import { WdxSpinnerModule } from '@wdx/shared/components/wdx-spinner';

@Component({
    standalone: true,
    imports: [CommonModule, RouterModule, AppShellComponent, WdxSpinnerModule],
    templateUrl: './app-page.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppPageComponent implements OnInit {
    constructor(
        private themeService: ThemeService,
        private fileApiService: FileApiService,
        private userApiService: UserApiService,
        private appService: AppService
    ) {}

    ngOnInit(): void {
        this.fileApiService
            .getFile(this.themeService.theme.fileIndex as string)
            .pipe(take(1))
            .subscribe((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob.body as Blob);
                reader.onloadend = () =>
                    this.appService.base64Logo$.next(reader.result as string);
            });

        this.userApiService
            .getTenant()
            .pipe(
                take(1),
                filter((tenant) => Boolean(tenant))
            )
            .subscribe((tenant) =>
                this.appService.tenantName$.next(tenant[0].tenantName as string)
            );
    }
}
