import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PartyApiService } from '../../../libs/api-contract';
import { Observable, firstValueFrom, shareReplay } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PartyAvatarService {
    private cache = new Map<string, Observable<HttpResponse<Blob>>>();

    constructor(private partyApiService: PartyApiService) {}

    getAvatar$(partyId: string) {
        const cacheKey = partyId;
        let result$ = this.cache.get(cacheKey);
        if (!result$) {
            result$ = this.partyApiService
                .getAvatar(partyId)
                .pipe(shareReplay(1));
            this.cache.set(cacheKey, result$);
        }
        return result$;
    }

    clearFromCache(partyId: string) {
        this.cache.delete(partyId);
    }

    async getBase64Avatar(partyId: string): Promise<string> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            return firstValueFrom(this.getAvatar$(partyId)).then((blob) => {
                if (blob.body) {
                    reader.readAsDataURL(blob.body);
                    reader.onloadend = () => resolve(reader.result as string);
                } else {
                    resolve('');
                }
            });
        });
    }
}
