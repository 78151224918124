import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalStorageService } from './local-storage/local-storage.service';

@Injectable({
    providedIn: 'root',
})
export class FaviconService {
    static FAVICON_KEY = 'Favicon';
    private localStorageService = inject(LocalStorageService);

    get faviconUrl(): string {
        return this.localStorageService.getStringKey(
            FaviconService.FAVICON_KEY
        ) as string;
    }

    set faviconUrl(tenantCode: string) {
        this.localStorageService.setStringKey(
            FaviconService.FAVICON_KEY,
            tenantCode
        );
    }

    private renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    changeFavicon(tenantCode: string) {
        this.faviconUrl = `https://static.clmi-dev.io/portal/favicon/${tenantCode.toLowerCase()}.png`;
        this.loadFavicon();
    }

    loadFavicon() {
        const url = this.faviconUrl;
        const link: HTMLLinkElement = this.renderer.createElement('link');
        link.rel = 'icon';
        link.type = 'image/png';
        link.href = url as string;

        const head = this.renderer.selectRootElement('head', true);
        const existingLinks = head.getElementsByTagName('link');

        for (let i = 0; i < existingLinks.length; i++) {
            if (existingLinks[i].rel === 'icon') {
                this.renderer.removeChild(head, existingLinks[i]);
            }
        }
        this.renderer.appendChild(head, link);
    }
}
