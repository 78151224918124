import {
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientsService } from '../services/clients/clients.service';

@Injectable()
export class ClientHttpInterceptor implements HttpInterceptor {
    constructor(private clientsService: ClientsService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (this.clientsService.activeClientId) {
            return next.handle(
                request.clone(
                    request.clone({
                        setHeaders: {
                            'x-clmi-portalclient':
                                this.clientsService.activeClientId,
                        },
                    })
                )
            );
        }
        return next.handle(request);
    }
}
